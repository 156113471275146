import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    addProductInOrder,
    applyOrderCouponPks, applyOrderCouponVisa,
    removeOrderCouponPks, removeOrderCouponVisa,
    splitOrder,
    writOffBonuses
} from "../../../lib/api/order";
import cn from "classnames";
import Button from "../../../components/ui/Button";
import icon_gift from "../../../assets/icons/icon_gift.svg";
import icon_close_x from "../../../assets/icons/icon_close_x.svg";
import s_Table from "./orderTable.module.scss";
import s from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import getNumbersWithSpaces from "../../../lib/getNumbersWithSpaces";
import Loader from "../../../components/ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../../store/orderEdit/constants";
import fetchAx from "../../../lib/fetchAx";
import { DOMAIN_API } from "../../../const/api";
import confirmDialog from "../../../lib/confirmDialog";
import { WONT_REMOVE } from "../../../const/text";
import errorMessage from "../../../lib/errorMessage";
import { getLoyaltyBalance } from "../../../lib/api/loyalty";
import ModalDelete from "./Modal/ModalDelete";
import Portal from "../../../components/ui/Portal";
import s_Portal from "../../../components/ui/Portal/Portal.module.scss";
import { parseDataGet } from "../api";
import { setNewISBPMDiscount } from "../../../store/orderEdit/action";
import { removePromoFunc } from "../helper/removePromo";

const OrderProductTable = ({
    formProps,
    form,
    setActiveAvailability,
    setActiveSpecialOffer,
    setCartAdditionalServices,
    setServicesChecked,
    setParamsServices,
    setProductIndex,
    setProductId,
    bonusData,
    checkEdit,
    statusDisableAll,
    productsData,
    setProductsData,
    totalPriceData,
    setTotalPriceData,
    setBonusData
}) => {
    const dispatch = useDispatch();
    const orderEdit = useSelector(state => state.orderEdit);

    const onChangeProduct = (key, value, id) => dispatch({
        type: Constants.ON_CHANGE_PRODUCT,
        payload: {key, value, id}
    });
    const onChangeProductService = (key, value, id, prodId) => dispatch({
        type: Constants.ON_CHANGE_PRODUCT_SERVICE,
        payload: {key, value, id, prodId}
    });
    const onChangeProductOffer = (key, value, id, prodId) => dispatch({
        type: Constants.ON_CHANGE_PRODUCT_OFFER,
        payload: {key, value, id, prodId}
    });

    const [jde, setJde] = useState('');
    const [disable, setDisable] = useState(false);
    const [load, setLoad] = useState(false);
    const [disableReturnQuantity, setDisableReturnQuantity] = useState(false);

    const [activeRemove, setActiveRemove] = useState(false);
    const [removeIndex, setRemoveIndex] = useState(null);
    const [removeId, setRemoveId] = useState(null);

    const navigate = useNavigate();

    const dataFields = formProps?.data && formProps?.data?.fields;

    const handleCloseRemove = () => {
        const portal = document.getElementById("modal-root");
        setActiveRemove(false);
        portal.classList.remove(s_Portal.wrap);
        setRemoveIndex(null);
        setRemoveId(null);
    }

    const removeService = async (index, number, id, prodId, additionalService) => {
        await confirmDialog(WONT_REMOVE);
        setLoad(true);

        if (dataFields?.id) {
            await removePromoFunc(dataFields?.id, formProps, dispatch)
        }

        if (dataFields?.has_pks_discount) {
            await removeOrderCouponPks(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }

                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    ...c,
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                    has_referenced_items: c.has_referenced_items
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        if (dataFields?.has_visa_discount) {
            await removeOrderCouponVisa(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }

                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    ...c,
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                    has_referenced_items: c.has_referenced_items
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        fetchAx({
            url: `${DOMAIN_API}/api/admin/order/${id}/products/${prodId}/additional-services/${additionalService}`,
            method: 'DELETE',
        }).then((res) => {
            setLoad(false);
            setTotalPriceData({
                total: res.data.total,
                refunded: res.data.refunded,
                price_final: res.data.price_final,
            });
            setProductsData((prev) => {
                const productsData = [...prev];

                if (productsData[index]) {
                    productsData[index].discount_absolute = res.data.discount_absolute;
                    productsData[index].discount_amount = res.data.discount_amount;
                    productsData[index].discount_bpm = res.data.discount_bpm;
                    productsData[index].discount_percent = res.data.discount_percent;
                    productsData[index].discount_pks = res.data.discount_pks;
                    productsData[index].discount_visa = res.data.discount_visa;
                    productsData[index].discount_visa_percent = res.data.discount_visa_percent;

                    productsData[index].product.additional_services?.splice(number, 1);
                    productsData[index].product = {
                        ...productsData[index]?.product,
                        price: res.data.products[index].price,
                        price_final: res.data.products[index].price_final,
                        total: res.data.products[index].total,
                        total_full: res.data.products[index].total_full,
                    };
                }

                return productsData;
            });
            setLoad(false);
        }).catch((err) => {
            setLoad(false);
            toast.error(err?.response?.data?.message || err?.response?.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const removeOffer = async (index, number, id, prodId, offerId) => {
        await confirmDialog(WONT_REMOVE);
        setLoad(true);

        if (dataFields?.id) {
            await removePromoFunc(dataFields?.id, formProps, dispatch)
        }

        if (dataFields?.has_pks_discount) {
            await removeOrderCouponPks(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }

                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    ...c,
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                    has_referenced_items: c.has_referenced_items
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        if (dataFields?.has_visa_discount) {
            await removeOrderCouponVisa(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }

                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    ...c,
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                    has_referenced_items: c.has_referenced_items
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        fetchAx({
            url: `${DOMAIN_API}/api/admin/order/${id}/products/${prodId}/special-offers/${offerId}`,
            method: 'DELETE',
        }).then((res) => {
            setLoad(false);
            setTotalPriceData({
                total: res.data.total,
                refunded: res.data.refunded,
                price_final: res.data.price_final,
            });
            setProductsData((prev) => {
                const productsData = [...prev];

                if (productsData[index]) {
                    productsData[index].discount_absolute = res.data.discount_absolute;
                    productsData[index].discount_amount = res.data.discount_amount;
                    productsData[index].discount_bpm = res.data.discount_bpm;
                    productsData[index].discount_percent = res.data.discount_percent;
                    productsData[index].discount_pks = res.data.discount_pks;
                    productsData[index].discount_visa = res.data.discount_visa;
                    productsData[index].discount_visa_percent = res.data.discount_visa_percent;

                    productsData[index].product.special_offers?.splice(number, 1);
                    productsData[index].product.special_offers?.splice(number, 1);
                    productsData[index].product.special_offers?.splice(number, 1);
                    productsData[index].product = {
                        ...productsData[index].product,
                        price: res.data.products[index].price,
                        price_final: res.data.products[index].price_final,
                        total: res.data.products[index].total,
                        total_full: res.data.products[index].total_full,
                    };
                }

                return productsData;
            });
            setLoad(false);
        }).catch((err) => {
            setLoad(false);
            toast.error(err?.response?.data?.message || err?.response?.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    const handleRemove = async (i, prodId) => {
        const removeProduct = () => fetchAx({
            url: `${DOMAIN_API}/api/admin/order/${formProps?.orderID}/products/${prodId}/destroy`,
            method: 'DELETE',
        });

        setLoad(true);

        if (dataFields?.has_pks_discount) {
            await removeOrderCouponPks(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }

                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    ...c,
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                    has_referenced_items: c.has_referenced_items
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        if (dataFields?.has_visa_discount) {
            await removeOrderCouponVisa(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }

                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                    has_referenced_items: c.has_referenced_items
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        handleCloseRemove();

        await removeProduct().then((res) => {
            setTotalPriceData({
                total: res.data.total,
                refunded: res.data.refunded,
                price_final: res.data.price_final,
            });
            toast('Продукт удален', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
            if (!!res?.data?.products?.length) {
                setProductsData(() => {
                    return res?.data.products.map((product, index) => {
                        const parseProduct = {
                            ...product,
                            product: {
                                ...product.product,
                                manager_id: product.manager_id,
                                price: product.price,
                                price_final: product.price_final,
                                total: product.total,
                                total_full: product.total_full,
                                additional_services: product.additional_services,
                                special_offers: product.special_offers,
                                discounts: product.discounts
                            },
                            manager: product.manager,
                            discount_pks: product.discount_pks,
                            manager_id: product.manager_id,
                            refunded: product.refunded,
                            has_bundles: product.has_bundles,
                            has_gifts: product.has_gifts,
                            has_discount_offers: product.has_discount_offers,
                            has_additional_services: product.has_additional_services,
                            id: product.id,
                            user_id: product.user_id,
                            user_type: product.user_type,
                            product_id: product.product_id,
                            quantity: product.quantity,
                            warehouse: product.warehouse,
                            purchase_quantity: product.purchase_quantity,
                            discount_amount: Math.round(product.discount_amount),
                            discount_absolute: product.discount_absolute,
                            discount_bpm: product.discount_bpm,
                            total: product.total,
                            discount_percent: product.discount_percent,
                            selected: product.selected,
                            return_quantity: product.return_quantity,
                            has_referenced_items: product.has_referenced_items
                        }
                        return parseProduct;
                    })
                });
                formProps.data.fields.discount_bpm = res?.data.discount_bpm
            } else {
                setProductsData((prev) => {
                    const productsData = [...prev];

                    if (productsData[i]) {
                        productsData.splice(i, 1);
                    }

                    return productsData;
                });
            }

            if (formProps?.data?.fields?.user?.phone || formProps?.data?.fields?.user_info?.phone) {
                getLoyaltyBalance(formProps?.data?.fields?.user?.phone || formProps?.data?.fields?.user_info?.phone)
                    .then((res) => setBonusData(res.data))
                    .catch(async (err) => await errorMessage(err, `/loyalty/user/balance/${formProps?.data?.fields?.user?.phone || formProps?.data?.fields?.user_info?.phone}`))
            }
        })
            .catch((err) => errorMessage(err, ''))
            .finally(() => setLoad(false))
    };

    const handleServiceRemove = (index, number) => {
        setProductsData((prev) => {
            const productsData = [...prev];

            if (productsData[index]) {
                productsData[index]?.product.additional_services?.splice(number, 1);
            }

            return productsData;
        });
    };

    const handleOffersRemove = (index, number) => {
        setProductsData((prev) => {
            const productsData = [...prev];

            if (productsData[index]) {
                productsData[index]?.product.special_offers?.splice(number, 1);
            }

            return productsData;
        });
    };

    const addProduct = async () => {
        setDisable(true);
        setLoad(true);

        try {
            if (!Object.keys(form?.errors)?.length) {

                if (dataFields?.id) {
                    await removePromoFunc(dataFields?.id, formProps, dispatch)
                }

                if (dataFields?.has_pks_discount) {
                    await removeOrderCouponPks(formProps?.orderID)
                        .then((res) => {
                            const parseData = parseDataGet(res?.data, formProps?.orderID);

                            if (formProps?.setData) {
                                formProps?.setData(prev => {
                                    const temp = {...prev};

                                    temp.fields = parseData;

                                    return temp;
                                })
                            }
                            if (!!res?.data?.products?.length) {
                                res?.data?.products?.map((c, id) => {
                                    setProductsData((prev) => {
                                        const productsData = [...prev];
                                        productsData[id] = {
                                            ...prev[id],
                                            ...c,
                                            product: {
                                                ...c.product,
                                                manager_id: c.manager_id,
                                                price: c.price,
                                                price_final: c.price_final,
                                                total: c.total,
                                                total_full: c.total_full,
                                                additional_services: c.additional_services,
                                                special_offers: c.special_offers,
                                                discounts: c.discounts
                                            },
                                            discount_pks: c.discount_pks,
                                            manager: c.manager,
                                            manager_id: c.manager_id,
                                            refunded: c.refunded,
                                            has_bundles: c.has_bundles,
                                            has_gifts: c.has_gifts,
                                            has_discount_offers: c.has_discount_offers,
                                            has_additional_services: c.has_additional_services,
                                            id: c.id,
                                            user_id: c.user_id,
                                            user_type: c.user_type,
                                            product_id: c.product_id,
                                            quantity: c.quantity,
                                            warehouse: c.warehouse,
                                            purchase_quantity: c.purchase_quantity,
                                            discount_amount: Math.round(c.discount_amount),
                                            discount_absolute: c.discount_absolute,
                                            discount_bpm: c.discount_bpm,
                                            total: c.total,
                                            discount_percent: c.discount_percent,
                                            selected: c.selected,
                                            return_quantity: c.return_quantity,
                                            has_referenced_items: c.has_referenced_items
                                        };
                                        return productsData;
                                    })
                                })
                            }

                        })
                        .catch(async (err) => await errorMessage(err, ''))
                }

                if (dataFields?.has_visa_discount) {
                    await removeOrderCouponVisa(formProps?.orderID)
                        .then((res) => {
                            const parseData = parseDataGet(res?.data, formProps?.orderID);

                            if (formProps?.setData) {
                                formProps?.setData(prev => {
                                    const temp = {...prev};

                                    temp.fields = parseData;

                                    return temp;
                                })
                            }
                            if (!!res?.data?.products?.length) {
                                res?.data?.products?.map((c, id) => {
                                    setProductsData((prev) => {
                                        const productsData = [...prev];
                                        productsData[id] = {
                                            ...prev[id],
                                            ...c,
                                            product: {
                                                ...c.product,
                                                manager_id: c.manager_id,
                                                price: c.price,
                                                price_final: c.price_final,
                                                total: c.total,
                                                total_full: c.total_full,
                                                additional_services: c.additional_services,
                                                special_offers: c.special_offers,
                                                discounts: c.discounts
                                            },
                                            discount_pks: c.discount_pks,
                                            manager: c.manager,
                                            manager_id: c.manager_id,
                                            refunded: c.refunded,
                                            has_bundles: c.has_bundles,
                                            has_gifts: c.has_gifts,
                                            has_discount_offers: c.has_discount_offers,
                                            has_additional_services: c.has_additional_services,
                                            id: c.id,
                                            user_id: c.user_id,
                                            user_type: c.user_type,
                                            product_id: c.product_id,
                                            quantity: c.quantity,
                                            warehouse: c.warehouse,
                                            purchase_quantity: c.purchase_quantity,
                                            discount_amount: Math.round(c.discount_amount),
                                            discount_absolute: c.discount_absolute,
                                            discount_bpm: c.discount_bpm,
                                            total: c.total,
                                            discount_percent: c.discount_percent,
                                            selected: c.selected,
                                            return_quantity: c.return_quantity,
                                            has_referenced_items: c.has_referenced_items
                                        };
                                        return productsData;
                                    })
                                })
                            }

                        })
                        .catch(async (err) => await errorMessage(err, ''))
                }

                await addProductInOrder(formProps?.orderID, jde).then((res) => {
                    setJde('');
                    setDisable(false);
                    setLoad(false);
                    setTotalPriceData({
                        total: res?.data?.total,
                        refunded: res?.data?.refunded,
                        price_final: res?.data?.price_final,
                    });

                    if (!!res?.data?.products?.length) {
                        setProductsData(() => {
                            return res?.data.products.map(product => {
                                return {
                                    ...product,
                                    discount_absolute: product.discount_absolute,
                                    discount_amount: Math.round(product.discount_amount),
                                    discount_bpm: product.discount_bpm,
                                    discount_percent: product.discount_percent,
                                    has_additional_services: product.has_additional_services,
                                    has_bundles: product.has_bundles,
                                    has_gifts: product.has_gifts,
                                    has_discount_offers: product.has_discount_offers,
                                    id: product.id,
                                    manager: product.manager,
                                    manager_id: product.manager_id,
                                    discount_pks: product.discount_pks,
                                    product: {
                                        ...product.product,
                                        manager_id: product.manager_id,
                                        price: product.price,
                                        price_final: product.price_final,
                                        total: product.total,
                                        total_full: product.total_full,
                                        additional_services: product.additional_services,
                                        special_offers: product.special_offers,
                                        discounts: product.discounts
                                    },
                                    product_id: product.product_id,
                                    purchase_quantity: product.purchase_quantity,
                                    quantity: product.quantity,
                                    refunded: product.refunded,
                                    return_quantity: product.return_quantity,
                                    selected: product.selected,
                                    total: product.total,
                                    user_id: product.user_id,
                                    user_type: product.user_type,
                                    warehouse: product.warehouse,
                                    has_referenced_items: product.has_referenced_items
                                };
                            })
                        });

                        formProps.data.fields.discount_bpm = res?.data.discount_bpm
                    }

                    if (formProps?.data?.fields?.user?.phone || formProps?.data?.fields?.user_info?.phone) {
                        getLoyaltyBalance(formProps?.data?.fields?.user?.phone || formProps?.data?.fields?.user_info?.phone)
                            .then((res) => setBonusData(res.data))
                            .catch(async (err) => await errorMessage(err, `/loyalty/user/balance/${formProps?.data?.fields?.user?.phone || formProps?.data?.fields?.user_info?.phone}`))
                    }
                    // applyHandler();
                })

            } else {
                toast.error('Заполните все поля', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                setLoad(false);
                setDisable(false);
            }
        } catch (err) {
            setLoad(false);
            setDisable(false);
            toast.error(err?.response?.data?.message || err?.response?.data, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    };

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            addProduct();
        }
    }

    const handleServicePopup = (data, index) => {
        setParamsServices({
            domain_id: dataFields?.domain_id,
            product_id: data.id,
        });
        setProductIndex(index);
        setCartAdditionalServices(true);
        setServicesChecked(data.product.additional_services);
    };

    const handleSpecialOfferPopup = (id, index) => {
        setProductIndex(index);
        setProductId(id);
        setActiveSpecialOffer(true);
    };

    const handleClickPks = async () => {
        if (formProps?.orderID) {
            setLoad(true);

            if (dataFields?.discount_bpm > 0) {
                toast.warning('Бонусы будут аннулированы', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }

            if (dataFields?.has_pks_discount) {
                await removeOrderCouponPks(formProps?.orderID)
                    .then((res) => window.location.reload())
                    .catch(async (err) => await errorMessage(err, ''))
                    .finally(() => setLoad(false));
            } else {
                if (dataFields?.discount_bpm && (dataFields?.discount_bpm > 0)) {
                    await writOffBonuses(formProps?.orderID, {discount_bpm: 0})
                        .then(async () => {
                            await applyOrderCouponPks(formProps?.orderID)
                                .then(async (res) => window.location.reload())
                                .catch(async (err) => {
                                    await errorMessage(err, '');

                                    setTimeout(() => window.location.reload(), 1000);
                                })
                                .finally(() => setLoad(false));
                        })
                        .catch(async (err) => await errorMessage(err, ''))
                        .finally(() => setLoad(false));
                } else {
                    await applyOrderCouponPks(formProps?.orderID)
                        .then((res) => {
                            const parseData = parseDataGet(res?.data, formProps?.orderID);

                            if (formProps?.setData) {
                                formProps?.setData(prev => {
                                    const temp = {...prev};

                                    temp.fields = parseData;

                                    return temp;
                                })
                            }

                            if (!!res?.data?.products?.length) {
                                res?.data?.products?.map((c, id) => {
                                    setProductsData((prev) => {
                                        const productsData = [...prev];
                                        productsData[id] = {
                                            ...prev[id],
                                            ...c,
                                            product: {
                                                ...c.product,
                                                manager_id: c.manager_id,
                                                price: c.price,
                                                price_final: c.price_final,
                                                total: c.total,
                                                total_full: c.total_full,
                                                additional_services: c.additional_services,
                                                special_offers: c.special_offers,
                                                discounts: c.discounts
                                            },
                                            discount_pks: c.discount_pks,
                                            manager: c.manager,
                                            manager_id: c.manager_id,
                                            refunded: c.refunded,
                                            has_bundles: c.has_bundles,
                                            has_gifts: c.has_gifts,
                                            has_discount_offers: c.has_discount_offers,
                                            has_additional_services: c.has_additional_services,
                                            id: c.id,
                                            user_id: c.user_id,
                                            user_type: c.user_type,
                                            product_id: c.product_id,
                                            quantity: c.quantity,
                                            warehouse: c.warehouse,
                                            purchase_quantity: c.purchase_quantity,
                                            discount_amount: Math.round(c.discount_amount),
                                            discount_absolute: c.discount_absolute,
                                            discount_bpm: c.discount_bpm,
                                            total: c.total,
                                            discount_percent: c.discount_percent,
                                            selected: c.selected,
                                            return_quantity: c.return_quantity,
                                            has_referenced_items: c.has_referenced_items
                                        };
                                        return productsData;
                                    })
                                })
                            }

                        })
                        .catch(async (err) => await errorMessage(err, ''))
                        .finally(() => setLoad(false));
                }
            }
        }
    }

    const handleClickVisa = async() => {
        if (formProps?.orderID){
            setLoad(true);

            if (dataFields?.has_visa_discount){
                await removeOrderCouponVisa(formProps?.orderID)
                    .then((res) => window.location.reload())
                    .catch(async(err) => await errorMessage(err, ''))
                    .finally(() => setLoad(false));
            } else {
                await applyOrderCouponVisa(formProps?.orderID)
                    .then((res) => {
                        const parseData = parseDataGet(res?.data, formProps?.orderID);

                        if (formProps?.setData){
                            formProps?.setData(prev => {
                                const temp = {...prev};

                                temp.fields = parseData;

                                return temp;
                            })
                        }

                        if (!!res?.data?.products?.length){
                            res?.data?.products?.map((c, id) => {
                                setProductsData((prev) => {
                                    const productsData = [...prev];
                                    productsData[id] = {
                                        ...prev[id],
                                        ...c,
                                        product: {
                                            ...c.product,
                                            manager_id: c.manager_id,
                                            price: c.price,
                                            price_final: c.price_final,
                                            total: c.total,
                                            total_full: c.total_full,
                                            additional_services: c.additional_services,
                                            special_offers: c.special_offers,
                                            discounts: c.discounts
                                        },
                                        discount_pks: c.discount_pks,
                                        manager: c.manager,
                                        manager_id: c.manager_id,
                                        refunded: c.refunded,
                                        has_bundles: c.has_bundles,
                                        has_gifts: c.has_gifts,
                                        has_discount_offers: c.has_discount_offers,
                                        has_additional_services: c.has_additional_services,
                                        id: c.id,
                                        user_id: c.user_id,
                                        user_type: c.user_type,
                                        product_id: c.product_id,
                                        quantity: c.quantity,
                                        warehouse: c.warehouse,
                                        purchase_quantity: c.purchase_quantity,
                                        discount_amount: Math.round(c.discount_amount),
                                        discount_absolute: c.discount_absolute,
                                        discount_bpm: c.discount_bpm,
                                        total: c.total,
                                        discount_percent: c.discount_percent,
                                        selected: c.selected,
                                        return_quantity: c.return_quantity,
                                        has_referenced_items: c.has_referenced_items
                                    };
                                    return productsData;
                                })
                            })
                        }

                    })
                    .catch(async(err) => await errorMessage(err, ''))
                    .finally(() => setLoad(false));
            }
        }
    }

    useEffect(() => {
        formProps?.data?.fields?.products?.map((c, id) => {
            setProductsData((prev) => {
                const productsData = [...prev];
                productsData[id] = {
                    ...prev[id],
                    ...c,
                    product: {
                        ...c.product,
                        manager_id: c.manager_id,
                        price: c.price,
                        price_final: c.price_final,
                        total: c.total,
                        total_full: c.total_full,
                        additional_services: c.additional_services,
                        special_offers: c.special_offers,
                        discounts: c.discounts
                    },
                    discount_pks: c.discount_pks,
                    manager: c.manager,
                    manager_id: c.manager_id,
                    refunded: c.refunded,
                    has_bundles: c.has_bundles,
                    has_gifts: c.has_gifts,
                    has_discount_offers: c.has_discount_offers,
                    has_additional_services: c.has_additional_services,
                    id: c.id,
                    user_id: c.user_id,
                    user_type: c.user_type,
                    product_id: c.product_id,
                    quantity: c.quantity,
                    warehouse: c.warehouse,
                    purchase_quantity: c.purchase_quantity,
                    discount_amount: Math.round(c.discount_amount),
                    discount_absolute: c.discount_absolute,
                    discount_bpm: c.discount_bpm,
                    total: c.total,
                    discount_percent: c.discount_percent,
                    selected: c.selected,
                    return_quantity: c.return_quantity,
                    has_referenced_items: c.has_referenced_items
                };
                return productsData;
            })
        })

        if ((dataFields?.status === 3) || (dataFields?.status === 1)) {
            setDisable(false);
        }
    }, []);

    const [maxLength, setMaxLength] = useState(0);
    const [bonus, setBonus] = useState('');
    const [bonusPlaceholder, setBonusPlaceholder] = useState(0);
    const [usedBonuses, setUsedBonuses] = useState(0);
    const [bonusDisable, setBonusDisable] = useState(true);
    const [productIdsSplit, setProductIdsSplit] = useState([]);

    const handleSplitOrder = async () => {
        setLoad(true);
        try {
            await splitOrder(formProps?.orderID, {order_product_ids: productIdsSplit}).then((res) => console.log(res));
            toast('Сделан подзаказ', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate('/app/orders');
        } catch (e) {
            setLoad(false);
            toast(e.response.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const setNumber = ({target}) => {
        if (target.value === '') {
            setBonus('');
        } else {
            let {value, min, max} = target;
            value = Math.max(Number(min), Math.min(Number(max), Number(value)));

            setBonus(Math.floor(value));
        }
    };

    useEffect(() => {
        const maxWritOffBonuses = Math.floor((+formProps?.data?.fields?.total * 30) / 100);
        if (bonusData?.balance?.active < maxWritOffBonuses) {
            setMaxLength(bonusData?.balance?.active);
            setBonusPlaceholder(bonusData?.balance?.active);
        } else if (bonusData?.balance?.active > maxWritOffBonuses) {
            setMaxLength(maxWritOffBonuses);
            setBonusPlaceholder(maxWritOffBonuses);
        }
        if (bonusData) {
            setBonusDisable(false);
        }
    }, [bonusData]);

    useEffect(() => {
        if ((dataFields?.applied_cart_rule_ids !== null) && (dataFields?.applied_cart_rule_ids !== "")) {
            setDisable(true);
        }
        if (dataFields?.used_bonuses) {
            setUsedBonuses(dataFields.used_bonuses);
        }
        if ((dataFields?.status === 19) || (dataFields?.status === 20) || (dataFields?.status === 21)) {
            setDisableReturnQuantity(true);
        }

        if ((typeof dataFields?.total === "number") || (typeof dataFields?.total === "string")) {
            setTotalPriceData(prev => ({
                ...prev,
                total: dataFields?.total,
            }));
        }

        if ((typeof dataFields?.refunded === "number") || (typeof dataFields?.refunded === "string")) {
            setTotalPriceData(prev => ({
                ...prev,
                refunded: dataFields?.refunded,
            }));
        }

        if ((typeof dataFields?.price_final === "number") || (typeof dataFields?.price_final === "string")) {
            setTotalPriceData(prev => ({
                ...prev,
                price_final: dataFields?.price_final,
            }));
        }

    }, [dataFields]);

    const handleOnChange = (value, name, index) => {
        setProductsData((prev) => {
            const result = [...prev];
            result[index][name] = value;
            return result;
        })
    };

    const handleOnChangeSpecialOffers = (e, name, index, specIndex) => {
        setProductsData((prev) => {
            const result = [...prev];
            result[index]['product']['special_offers'][specIndex][name] = e.target.value;
            return result;
        })
    };

    const handleClickBonus = async () => {
        try {
            setBonusDisable(true);
            await writOffBonuses(formProps?.orderID, {discount_bpm: bonus}).then(async (res) => {
                if (dataFields?.has_pks_discount) {
                    toast.warning('Промокод ПКС будет аннулирован', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    await removeOrderCouponPks(formProps?.orderID)
                        .then((res) => window.location.reload())
                        .catch(async (err) => await errorMessage(err, ''))
                }

                if (!!res?.data?.products?.length) {
                    setUsedBonuses(res.data.used_bonuses);
                    setProductsData((prev) => {
                        const temp = [...prev];
                        for (let i = 0; i < temp.length; i++) {
                            temp[i] = {
                                ...temp[i],
                                discount_bpm: res.data.products[i].discount_bpm,
                                product: {
                                    ...temp[i].product,
                                    price: res.data.products[i].price,
                                    price_final: res.data.products[i].price_final,
                                }
                            }
                        }
                        return temp;
                    });
                    toast('Бонусы списаны', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    location.reload();
                    setBonusDisable(false);
                } else {
                    setBonusDisable(false);
                    toast.error(res?.data?.last_error || 'Ошибка связи с api-сервером', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        } catch (e) {
            setBonusDisable(false);
            toast(e.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const checkSuperAdmin = JSON.parse(localStorage?.getItem('roles'))?.includes('super-admin');

    /*PKSDiscount*/
    useEffect(() => {
        if (productsData.some(el => el.discount_bpm) && orderEdit?.delivery_type === '6' && orderEdit?.type_payment === '1') {
            dispatch(setNewISBPMDiscount(true))
        } else {
            dispatch(setNewISBPMDiscount(false))
        }
    }, [productsData, orderEdit?.delivery_type, orderEdit?.type_payment]);

    return (
        <div className={s_Table.table__inner}>
            <div className={s_Table.table__wrapper}>
                <table className={s_Table.table}>
                    <thead className={s_Table.thead}>
                    <tr>
                        <th/>
                        <th>
                            <div>JDE / код МП</div>
                        </th>
                        <th>
                            <div>Название товара</div>
                        </th>
                        <th>
                            <div>Закупка</div>
                        </th>
                        <th>
                            <div>Наличие</div>
                        </th>
                        <th>
                            <div>Кол-во</div>
                        </th>
                        <th>
                            <div>Возврат</div>
                        </th>
                        <th>
                            <div>Скидка, BPM</div>
                        </th>
                        <th>
                            <div>Скидка, Visa%</div>
                        </th>
                        <th>
                            <div>ПКС</div>
                        </th>
                        <th>
                            <div>Скидка, %</div>
                        </th>
                        <th>
                            <div>Скидка, абс</div>
                        </th>
                        <th>
                            <div>Вых. цена</div>
                        </th>
                        <th>
                            <div>Стоимость</div>
                        </th>
                        <th>
                            <div>Менеджер</div>
                        </th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody className={s_Table.tbody}>
                    {productsData?.map((item, index) => {
                        const checkService = item?.product?.additional_services?.length !== 0;
                        const checkOffers = item?.product?.special_offers?.length !== 0;
                        let purchaseWatch = form.watch(`products[${index}].purchase_quantity`);
                        let disabledPercent = false;
                        let disabledAmount = false;

                        const filterWarehouses = item?.product?.warehouse_quantity_main?.filter(ware => {
                            switch (ware.number.toString()) {
                                case('310'):
                                case('513'):
                                case('610'):
                                case('699'):
                                case('511'): {
                                    return true;
                                }
                                default:
                                    return false;
                            }
                        });

                        if (+item?.discount_amount > 0) {
                            disabledPercent = true
                        }

                        if ((+item?.discount_percent >= 1) && (+item?.discount_amount >= 1)) {
                            disabledPercent = false
                        }

                        if (+item?.discount_percent > 0) {
                            disabledAmount = true
                        }

                        if (purchaseWatch?.length === 0) {
                            purchaseWatch = '';
                        }

                        return (
                            <Fragment key={`table-body-item-${index}`}>
                                <tr>
                                    <td>
                                        <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                            <input
                                                className={s.checkbox}
                                                type="checkbox"
                                                id={`table_product_${index}`}
                                                name={`products[${index}].selected`}
                                                defaultChecked={false}
                                                onChange={() => {
                                                    if (!!productIdsSplit.length) {
                                                        setProductIdsSplit(prev => {
                                                            const temp = [...prev];
                                                            const result = temp.filter(prod => {
                                                                return prod !== item.id
                                                            })
                                                            if (!temp.find(prod => prod === item.id)) {
                                                                return [...result, item.id]
                                                            } else {
                                                                return result;
                                                            }
                                                        });
                                                    } else {
                                                        setProductIdsSplit([item.id]);
                                                    }
                                                }}
                                                ref={form.register}
                                                disabled={statusDisableAll ? statusDisableAll : !checkEdit}
                                            />
                                            <label htmlFor={`table_product_${index}`}
                                                   className={s.label}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={s_Table.jde}>{item.product.jde}</div>
                                        {checkSuperAdmin && (
                                            <div
                                                className={`${s_Table.jde} ${s_Table.price_all_discount} ${s_Table.price_all} ${s_Table.id}`}>
                                                ID: {item?.id}
                                            </div>
                                        )}
                                        <input
                                            type="hidden"
                                            ref={form.register}
                                            name={`products[${index}].product_id`}
                                            value={item.product_id}
                                        />
                                        <input
                                            type="hidden"
                                            ref={form.register}
                                            name={`products[${index}].user_id`}
                                            value={item.user_id}
                                        />
                                        <input
                                            type="hidden"
                                            ref={form.register}
                                            name={`products[${index}].user_type`}
                                            value={item.user_type}
                                        />
                                        <input
                                            type="hidden"
                                            ref={form.register}
                                            name={`products[${index}].domain_id`}
                                            value={item.domain_id}
                                        />
                                        <input
                                            type="hidden"
                                            ref={form.register}
                                            name={`products[${index}].warehouse_id`}
                                            value={item.warehouse_id}
                                        />
                                    </td>
                                    <td>
                                        <div>
                                            <div>
                                                <div style={{display: 'flex'}}>
                                                    {checkService && <div className={cn(s_Table.service__block, {
                                                        [s_Table.service__block_second]: index === 1,
                                                        [s_Table.service__block_third]: index === 2,
                                                    })}/>}
                                                    {checkOffers && <div className={cn(s_Table.service__block, {
                                                        [s_Table.offer__block]: index === 0,
                                                        [s_Table.offer__block_2]: index === 1,
                                                        [s_Table.offer__block_3]: index === 2,
                                                        [s_Table.offer__block_4]: index === 3,
                                                        [s_Table.offer__block_5]: index === 4,
                                                    })}
                                                    />}
                                                </div>
                                                <Link
                                                    to={`/app/product/update/${item.product.id}`}><strong>{item.product.title_ua}</strong>
                                                </Link>
                                                {item?.is_markdown && (
                                                    <div style={{ color: 'red', fontWeight: 700, fontSize: '14px' }}>
                                                        Уцінка
                                                    </div>
                                                )}
                                            </div>
                                            <div className={s_Table.btn__wrapper}>
                                                {(item?.has_gifts || item?.has_bundles || item?.has_discount_offers) && (
                                                    <div>
                                                        <Button
                                                            onClick={() => handleSpecialOfferPopup(item.id, index)}
                                                            disabled={statusDisableAll ? statusDisableAll : !checkEdit}
                                                            purple
                                                            small
                                                        >
                                                            <img src={icon_gift} alt="Icon gift"/>
                                                            Акції
                                                        </Button>
                                                    </div>
                                                )}
                                                {(item.has_additional_services) && (
                                                    <div>
                                                        <Button
                                                            onClick={() => handleServicePopup(item, index)}
                                                            disabled={!checkEdit}
                                                            border
                                                            small
                                                        >
                                                            <svg width="12" height="12" viewBox="0 0 12 12"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="0.6" y="0.6" width="10.8" height="10.8"
                                                                      rx="1.9" strokeWidth="1.2"/>
                                                                <rect x="5.3999" y="3" width="1.2" height="6" rx="0.6"/>
                                                                <rect x="9" y="5.3999" width="1.2" height="6" rx="0.6"
                                                                      transform="rotate(90 9 5.3999)"/>
                                                            </svg>
                                                            Сервіси
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={s_Table.purchase__block}>
                                            <div className={s_Table.purchase__price}>699</div>
                                            <div>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    defaultValue={item.purchase_quantity || ''}
                                                    placeholder={item.purchase_quantity || '0'}
                                                    value={purchaseWatch}
                                                    name={`products[${index}].purchase_quantity`}
                                                    onChange={(e) => {
                                                        onChangeProduct('purchase_quantity', e.target.value, +item.id);
                                                        setProductsData(prev => {
                                                            const temp = [...prev];
                                                            temp[index] = {
                                                                ...temp[index],
                                                                purchase_quantity: e.target.value
                                                            }
                                                            return temp;
                                                        })
                                                    }}
                                                    readOnly={statusDisableAll}
                                                    ref={form.register}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {filterWarehouses.map((ware, indProdWare) => (
                                                <div className={s_Table.availability__block}
                                                     key={`product-warehouses-${indProdWare}`}>
                                                    <div className={s_Table.availability__stock}>
                                                        {ware.number.toString() === '310' ? '510' : ware.number}
                                                    </div>
                                                    <div className={s_Table.availability__counter}>
                                                        {ware.number.toString() === '699' ? ware.quantity ? '+' : ware.quantity : ware.quantity}
                                                    </div>
                                                </div>
                                            ))}
                                            <div>
                                                <Button
                                                    purple
                                                    full
                                                    small
                                                    onClick={() => {
                                                        setActiveAvailability(true);
                                                        setProductId(item.product_id);
                                                    }}
                                                    disabled={!checkEdit}
                                                >
                                                    Наличие
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input
                                                type="number"
                                                min={0}
                                                ref={form.register}
                                                name={`products[${index}].quantity`}
                                                value={item.quantity || ''}
                                                placeholder={item.quantity || '0'}
                                                onChange={(e) => {
                                                    onChangeProduct('quantity', +e.target.value, +item.id);
                                                    setProductsData(prev => {
                                                        const temp = [...prev];
                                                        temp[index] = {
                                                            ...temp[index],
                                                            quantity: e.target.value
                                                        }
                                                        return temp;
                                                    })
                                                }}
                                                readOnly={!checkEdit ? !checkEdit : !['3', '33'].includes(dataFields?.status?.toString())}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div className={cn('', {
                                            [s_Table.disable_return_quantity]: disableReturnQuantity
                                        })}>
                                            <input
                                                type="number"
                                                min={0}
                                                value={item.return_quantity || ''}
                                                placeholder={item.return_quantity || '0'}
                                                ref={form.register}
                                                name={`products[${index}].return_quantity`}
                                                onChange={(e) => {
                                                    onChangeProduct('return_quantity', +e.target.value, +item.id);
                                                    setProductsData(prev => {
                                                        const temp = [...prev];
                                                        temp[index] = {
                                                            ...temp[index],
                                                            return_quantity: e.target.value
                                                        }
                                                        return temp;
                                                    })
                                                }}
                                                readOnly={disableReturnQuantity ? !disableReturnQuantity : statusDisableAll}
                                                disabled
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input
                                                style={item.discount_bpm && orderEdit?.isBPMDiscount?.status ? {border: '3px solid red'} : {}}
                                                type="number"
                                                min={0}
                                                value={item.discount_bpm || 0}
                                                disabled
                                                readOnly={statusDisableAll}
                                            />
                                            <input
                                                type="hidden"
                                                value={item.discount_bpm || 0}
                                                ref={form.register}
                                                name={`products[${index}].discount_bpm`}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input
                                                type="text"
                                                value={item?.discount_visa || 0}
                                                ref={form.register}
                                                name={`products[${index}].discount_visa`}
                                                readOnly
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input
                                                type="number"
                                                min={0}
                                                value={item?.discount_pks || 0}
                                                disabled
                                                readOnly={statusDisableAll}
                                            />
                                            <input
                                                type="hidden"
                                                value={item?.discount_pks || 0}
                                                ref={form.register}
                                                name={`products[${index}].discount_pks`}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input
                                                type="number"
                                                key={`item-percent-${index}`}
                                                value={productsData?.[index]?.discount_percent}
                                                placeholder={item.discount_percent || '0'}
                                                onChange={(e) => {
                                                    if ((!!e?.target?.value?.length >= 1) && (e?.target?.value?.split('')?.[0] === '0')) {
                                                        e.target.value = e.target.value.slice(1);
                                                    }

                                                    if (/^\d+$/.test(e.target.value)) {
                                                        onChangeProduct('discount_percent', +e.target.value, +item.id);
                                                        handleOnChange(+e.target.value, 'discount_percent', index);
                                                    } else {
                                                        onChangeProduct('discount_percent', 0, +item.id);
                                                        handleOnChange(0, 'discount_percent', index);
                                                    }
                                                }}
                                                readOnly={statusDisableAll ? statusDisableAll : disabledPercent}
                                            />
                                            <input
                                                type="hidden"
                                                value={productsData?.[index]?.discount_percent || 0}
                                                ref={form.register}
                                                name={`products[${index}].discount_percent`}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input
                                                type="number"
                                                key={`item-amount-${index}`}
                                                value={productsData?.[index]?.discount_amount}
                                                placeholder={item.discount_amount || '0'}
                                                onChange={(e) => {
                                                    if ((!!e?.target?.value?.length >= 1) && (e?.target?.value?.split('')?.[0] === '0')) {
                                                        e.target.value = e.target.value.slice(1);
                                                    }

                                                    if (/^\d+$/.test(e.target.value)) {
                                                        onChangeProduct('discount_amount', +e.target.value, +item.id);
                                                        handleOnChange(+e.target.value, 'discount_amount', index);
                                                    } else {
                                                        onChangeProduct('discount_amount', 0, +item.id);
                                                        handleOnChange(0, 'discount_amount', index);
                                                    }
                                                }}
                                                readOnly={statusDisableAll ? statusDisableAll : disabledAmount}
                                            />
                                            <input
                                                type="hidden"
                                                value={item.discount_amount || 0}
                                                ref={form.register}
                                                name={`products[${index}].discount_amount`}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div className={s_Table.price_all}>
                                                {getNumbersWithSpaces(+item.product.price_final)}
                                            </div>

                                            <div className={`${s_Table.price_all_discount} ${s_Table.price_all}`}>
                                                ({getNumbersWithSpaces(+item.product.price)})
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <div className={s_Table.price_all}>
                                                {getNumbersWithSpaces(Math.round(+item.product.total - +item.refunded))}
                                            </div>
                                            <div className={`${s_Table.price_all_discount} ${s_Table.price_all}`}>
                                                ({getNumbersWithSpaces(+item.product.total_full)})
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className={s_Table.manager__name}>
                                            {item?.manager ? item.manager.name : '---'}
                                        </div>
                                        <input
                                            type="hidden"
                                            ref={form.register}
                                            name={`products[${index}].manager_id`}
                                            value={item.product.manager_id}
                                        />
                                    </td>
                                    <td>
                                        <div>
                                            <button
                                                type="button"
                                                className={s_Table.btn__delete}
                                                onClick={ async () => {
                                                    if(!item?.has_referenced_items){
                                                        setActiveRemove(true);
                                                        setRemoveIndex(index);
                                                        setRemoveId(item.id);

                                                        if (dataFields?.id) {
                                                            await removePromoFunc(dataFields?.id, formProps, dispatch)
                                                        }
                                                    } else {
                                                        const err = {response: {status: "has_referenced_items"}}
                                                        await errorMessage(err, `Цей товар пов'язаний з іншим замовленням`)
                                                    }
                                                }}
                                                disabled={!checkEdit ? !checkEdit : !['3', '33'].includes(dataFields?.status?.toString())}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                {item.product.additional_services?.map((service, ind) => (
                                    <tr key={`additional-services-${ind}`} className={cn('', {
                                        [s_Table.color_service_1]: checkService && (index === 0),
                                        [s_Table.color_service_2]: checkService && (index === 1),
                                        [s_Table.color_service_3]: checkService && (index === 2),
                                    })}>
                                        <td/>
                                        <td>
                                            <div className={s_Table.jde}>{service?.additional_service?.jde}</div>
                                            {checkSuperAdmin && (
                                                <div
                                                    className={`${s_Table.jde} ${s_Table.price_all_discount} ${s_Table.price_all}`}>
                                                    ID: {service?.id}
                                                </div>
                                            )}
                                            <input
                                                type="hidden"
                                                ref={form.register}
                                                name={`products[${index}].additional_services[${ind}].additional_service_id`}
                                                value={service?.additional_service_id}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                {checkService && <div className={cn(s_Table.service__block, {
                                                    [s_Table.service__block_second]: index === 1,
                                                    [s_Table.service__block_third]: index === 2,
                                                })}/>}
                                                <div>{service?.additional_service?.title_ua || service?.title}</div>
                                            </div>
                                        </td>
                                        <td/>
                                        <td/>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    max={item?.quantity}
                                                    defaultValue={service?.quantity}
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services[${ind}].quantity`}
                                                    onChange={(e) => onChangeProductService('quantity', +e.target.value, +service.id, +item.id)}
                                                    readOnly={!checkEdit ? !checkEdit : !['3', '33'].includes(dataFields?.status?.toString())}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className={cn('', {
                                                [s_Table.disable_return_quantity]: disableReturnQuantity
                                            })}>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    defaultValue={service?.return_quantity || ''}
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services[${ind}].return_quantity`}
                                                    onChange={(e) => onChangeProductService('return_quantity', +e.target.value, +service.id, +item.id)}
                                                    readOnly={statusDisableAll}
                                                    placeholder={service.return_quantity || '0'}
                                                    disabled
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    min={0}
                                                    value={service.discount_bpm || 0}
                                                    disabled
                                                    readOnly={statusDisableAll}
                                                />
                                                <input
                                                    type="hidden"
                                                    value={service.discount_bpm || 0}
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services[${ind}].discount_bpm`}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="text"
                                                    value={service?.discount_visa || 0}
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services[${ind}].discount_visa`}
                                                    readOnly
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input type="number" defaultValue="0" disabled/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    value={service?.discount_percent}
                                                    placeholder={service?.discount_percent || '0'}
                                                    readOnly
                                                />
                                                <input
                                                    type="hidden"
                                                    value={service?.discount_percent || 0}
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services.[${ind}].discount_percent`}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <input
                                                    type="number"
                                                    value={productsData?.[index]?.additional_services?.[ind]?.discount_amount}
                                                    placeholder={service?.discount_amount || 0}
                                                    readOnly
                                                />
                                                <input
                                                    type="hidden"
                                                    value={productsData?.[index]?.additional_services?.[ind]?.discount_amount || 0}
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services.[${ind}].discount_amount`}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div
                                                    className={s_Table.price_all}>{getNumbersWithSpaces(Math.round(service?.price))}</div>
                                                <input
                                                    type="hidden"
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services[${ind}].price`}
                                                    value={Math.round(service?.price)}
                                                />
                                            </div>

                                        </td>
                                        <td>
                                            <div>
                                                <div className={s_Table.price_all}>
                                                    {getNumbersWithSpaces(Math.round(+service.total - +service.refunded))}
                                                </div>
                                                <div className={`${s_Table.price_all_discount} ${s_Table.price_all}`}>
                                                    ({getNumbersWithSpaces(+service.total_full)})
                                                </div>
                                                <input
                                                    type="hidden"
                                                    ref={form.register}
                                                    name={`products[${index}].additional_services[${ind}].total_full`}
                                                    value={Math.round(service?.total_full)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className={s_Table.manager__name}>
                                                {item?.manager ? item.manager.name : '---'}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <button type="button" className={s_Table.btn__delete}
                                                        onClick={() => removeService(index, ind, formProps?.orderID, item.id, service?.id)}
                                                        disabled={!checkEdit ? !checkEdit : !['3', '33'].includes(dataFields?.status?.toString())}/>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {item.product.special_offers?.map((offers, i) => {
                                    const filterOffersWarehouses = offers?.promo_product?.warehouse_quantity_main?.filter(ware => {
                                        switch (ware.number.toString()) {
                                            case('310'):
                                            case('513'):
                                            case('610'):
                                            case('699'):
                                            case('511'): {
                                                return true;
                                            }
                                            default:
                                                return false;
                                        }
                                    });

                                    let disabledOfferPercent = false;
                                    let disabledOfferAmount = false;

                                    if ((+offers.discount_amount > 0)) {
                                        disabledOfferPercent = true
                                    }

                                    if ((+offers.discount_percent >= 1) && (+offers.discount_amount >= 1)) {
                                        disabledOfferPercent = false
                                    }
                                    if (+offers.discount_percent > 0) {
                                        disabledOfferAmount = true
                                    }

                                    return (
                                        <tr key={`special-offers-${i}`} className={cn('', {
                                            [s_Table.color_offer_1]: checkOffers && (index === 0),
                                            [s_Table.color_offer_2]: checkOffers && (index === 1),
                                            [s_Table.color_offer_3]: checkOffers && (index === 2),
                                            [s_Table.color_offer_4]: checkOffers && (index === 3),
                                            [s_Table.color_offer_5]: checkOffers && (index === 4),
                                        })}>
                                            <td/>
                                            <td>
                                                <div className={s_Table.jde}>{offers?.promo_product?.jde}</div>
                                                {checkSuperAdmin && (
                                                    <div
                                                        className={`${s_Table.jde} ${s_Table.price_all_discount} ${s_Table.price_all}`}>
                                                        ID: {offers?.id}
                                                    </div>
                                                )}
                                                <input
                                                    type="hidden"
                                                    ref={form.register}
                                                    name={`products[${index}].special_offers[${i}].gift_id`}
                                                    value={offers?.promo_product?.id}
                                                />
                                                <input
                                                    type="hidden"
                                                    ref={form.register}
                                                    name={`products[${index}].special_offers[${i}].special_offer_id`}
                                                    value={offers?.special_offer_id}
                                                />
                                                <input
                                                    type="hidden"
                                                    ref={form.register}
                                                    name={`products[${index}].special_offers[${i}].order_product_id`}
                                                    value={offers?.order_product_id}
                                                />
                                            </td>
                                            <td>
                                                <div>
                                                    {checkOffers && <div className={cn(s_Table.service__block, {
                                                        [s_Table.offer__block]: index === 0,
                                                        [s_Table.offer__block_2]: index === 1,
                                                        [s_Table.offer__block_3]: index === 2,
                                                        [s_Table.offer__block_4]: index === 3,
                                                        [s_Table.offer__block_5]: index === 4,
                                                    })}
                                                    />}
                                                    <Link to={`/app/product/update/${offers?.promo_product?.id}`}>
                                                        <strong>
                                                            {offers?.promo_product?.title}
                                                        </strong>
                                                    </Link>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={s_Table.purchase__block}>
                                                    <div className={s_Table.purchase__price}>699</div>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            defaultValue={item.purchase_quantity || 0}
                                                            name={`products[${index}].special_offers[${i}].purchase_quantity`}
                                                            ref={form.register}
                                                            readOnly={statusDisableAll}
                                                            onChange={(e) => onChangeProductOffer('purchase_quantity', +e.target.value, +offers.id, +item.id)}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    {!!filterOffersWarehouses?.length && filterOffersWarehouses.map((ware, indOfferWare) => (
                                                        <div className={s_Table.availability__block}
                                                             key={`offers-warehouses-${indOfferWare}`}>
                                                            <div
                                                                className={s_Table.availability__stock}>{ware.number}</div>
                                                            <div className={s_Table.availability__counter}>
                                                                {ware.number.toString() === '699' ? ware.quantity ? '+' : ware.quantity : ware.quantity}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div>
                                                        <Button
                                                            purple
                                                            full
                                                            small
                                                            onClick={() => {
                                                                setActiveAvailability(true);
                                                                setProductId(offers?.promo_product?.id);
                                                            }}
                                                            disabled={!checkEdit}
                                                        >
                                                            Наличие
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        type="number"
                                                        defaultValue={offers?.quantity}
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].quantity`}
                                                        min={0}
                                                        max={item?.quantity}
                                                        readOnly={!checkEdit ? !checkEdit : !['3', '33'].includes(dataFields?.status?.toString())}
                                                        onChange={(e) => onChangeProductOffer('quantity', +e.target.value, +offers.id, +item.id)}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className={cn('', {
                                                    [s_Table.disable_return_quantity]: disableReturnQuantity
                                                })}>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        defaultValue={offers?.return_quantity || ''}
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].return_quantity`}
                                                        onChange={(e) => onChangeProductOffer('return_quantity', +e.target.value, +offers.id, +item.id)}
                                                        readOnly={statusDisableAll}
                                                        placeholder={offers.return_quantity || '0'}
                                                        disabled
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        value={offers.discount_bpm || 0}
                                                        disabled
                                                        readOnly={statusDisableAll}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        value={offers.discount_bpm || 0}
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].discount_bpm`}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        type="text"
                                                        value={offers?.discount_visa || 0}
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].discount_visa`}
                                                        readOnly
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        value={offers?.discount_pks || 0}
                                                        disabled
                                                        readOnly={statusDisableAll}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        value={offers?.discount_pks || 0}
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].discount_pks`}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        key={`item-offers-percent-${i}`}
                                                        value={offers.discount_percent || ''}
                                                        placeholder={offers.discount_percent || '0'}
                                                        readOnly
                                                    />
                                                    <input
                                                        type="hidden"
                                                        value={offers.discount_percent || 0}
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].discount_percent`}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <input
                                                        type="number"
                                                        key={`item-offers-amount-${i}`}
                                                        value={offers.discount_amount || ''}
                                                        placeholder={offers.discount_amount || '0'}
                                                        readOnly
                                                    />
                                                    <input
                                                        type="hidden"
                                                        value={offers.discount_amount || 0}
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].discount_amount`}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div
                                                        className={s_Table.price_all}>{getNumbersWithSpaces(offers?.price)}</div>
                                                    <input
                                                        type="hidden"
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].price`}
                                                        value={offers?.price}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <div className={s_Table.price_all}>
                                                        {getNumbersWithSpaces(Math.round(+offers.total - +offers.refunded))}
                                                    </div>
                                                    <div
                                                        className={`${s_Table.price_all_discount} ${s_Table.price_all}`}>
                                                        ({getNumbersWithSpaces(+offers.total_full)})
                                                    </div>
                                                    <input
                                                        type="hidden"
                                                        ref={form.register}
                                                        name={`products[${index}].special_offers[${i}].total_full`}
                                                        value={offers?.total_full}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div className={s_Table.manager__name}>
                                                    {item?.manager ? item?.manager?.name : '---'}
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <button type="button" className={s_Table.btn__delete}
                                                            onClick={() => removeOffer(index, i, formProps?.orderID, item.id, offers.id)}
                                                            disabled={!checkEdit ? !checkEdit : !['3', '33'].includes(dataFields?.status?.toString())}/>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </Fragment>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <div className={s_Table.table__footer}>
                <div>
                    <div className={s_Table.suborder}>
                        <div>
                            <Button purple small onClick={() => handleSplitOrder(dataFields)}
                                    disabled={!checkEdit || !productIdsSplit.length}>Отделить в подзаказ</Button>
                        </div>
                    </div>
                    <div className={s_Table.add_product}>
                        <div>
                            <input
                                type="number"
                                min={0}
                                value={jde}
                                disabled={!checkEdit ? !checkEdit : !['1', '3', '33'].includes(dataFields?.status?.toString())}
                                onChange={({target}) => setJde(target.value)}
                                onKeyDown={onEnterPress}
                                placeholder="Введите JDE"
                            />
                        </div>
                        <div>
                            <Button
                                purple
                                small
                                onClick={addProduct}
                                disabled={!checkEdit ? !checkEdit : !['1', '3', '33'].includes(dataFields?.status?.toString())}
                            >
                                Добавить товар
                            </Button>
                        </div>
                    </div>
                </div>
                <div>
                    <Button
                        onClick={handleClickVisa}
                        purple={!dataFields?.has_visa_discount}
                        white={dataFields?.has_visa_discount}
                        style={{marginRight: 10, minWidth: "120px"}}
                        disabled={dataFields?.status_payment?.toString() === '2' ? true : bonusDisable || !checkEdit || statusDisableAll}
                    >
                        Виза 5%
                        {dataFields?.has_visa_discount && <img src={icon_close_x} alt="cancel pks"/>}
                    </Button>
                    <Button
                        onClick={handleClickPks}
                        purple={!dataFields?.has_pks_discount}
                        white={dataFields?.has_pks_discount}
                        style={{marginRight: 10}}
                        disabled={dataFields?.status_payment?.toString() === '2' ? true : bonusDisable || !checkEdit || statusDisableAll}
                    >
                        ПКС
                        {dataFields?.has_pks_discount && <img src={icon_close_x} alt="cancel pks"/>}
                    </Button>
                    {dataFields?.domain_id?.toString() !== '2' && (
                        <div className={s_Table.bonus_user}>
                            <div>
                                <input
                                    type="hidden"
                                    name="used_bonuses"
                                    value={usedBonuses}
                                    ref={form.register}
                                />
                                <input
                                    type="number"
                                    id="table_used_bonuses"
                                    name="used_bonuses"
                                    value={bonus}
                                    placeholder={bonusPlaceholder}
                                    max={maxLength}
                                    min="-10000"
                                    onChange={(e) => setNumber(e)}
                                    readOnly={dataFields?.status_payment?.toString() === '2' ? true : statusDisableAll ? statusDisableAll : bonusDisable}
                                />
                                <label htmlFor="table_used_bonuses">Бонусов:</label>
                            </div>
                            <div>
                                <Button purple small onClick={handleClickBonus}
                                        disabled={dataFields?.status_payment?.toString() === '2' ? true : bonusDisable || !checkEdit || statusDisableAll}>Списать</Button>
                            </div>
                        </div>
                    )}
                    <div className={s_Table.total_price}>
                        <div>
                            Итого:<b>{totalPriceData.total ? getNumbersWithSpaces(Math.round(totalPriceData.total - totalPriceData.refunded)) : 0}</b><span> ({totalPriceData.price_final ? getNumbersWithSpaces(Math.round(totalPriceData.price_final)) : 0})</span>
                        </div>
                    </div>
                </div>
            </div>

            {activeRemove && (
                <Portal children={<ModalDelete
                    active={activeRemove}
                    setActive={handleCloseRemove}
                    funcRemove={handleRemove}
                    indexProduct={removeIndex}
                    idProduct={removeId}
                    title='Видалити'
                />} onClose={handleCloseRemove}/>
            )}
            {load && <Loader/>}
        </div>
    );
};

export default OrderProductTable;
