import React, {memo, useEffect, useRef, useState} from 'react';
import s from './SelectInput.module.scss';
import removeDuplicates from "../../../lib/helpers/removeDuplicates";
import errorMessage from "../../../lib/errorMessage";
import { refreshToken } from "../../../lib/crud/refreshToken";

const SelectInput = memo(({
  title = '', name = '', list = [], className= '', methods = {
    set: () => { },
  }, multi, api, searchBy = '', apiSearch, defVal, fields, visibleSelected = true, domainID = null, defData, styleWrapper = {}, idInput = ''
}) => {
  const selectInputRef = useRef();
  const [isActive, setIsActive] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [selectedItemList, setSelectedItemList] = useState([]);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (!selectInputRef?.current?.contains(e.target)) setIsActive(false);
    });

    return document.removeEventListener('click', () => {});
  }, []);
  /* eslint-disable */
  useEffect(() => {
    if (searchBy !== 'value' && !apiSearch) {
      if (api && selectedItemList.length !== 0) {
        const ids = selectedItemList.map((el) => el.id);
        api.get({
          [title]: ids,
        }).then((r) => {
          if (r.data.data.length !== 0) {
            methods?.set(r.data.data);
          }
        }).catch(async (err) => {
          if(err?.response?.status === 401) {
            await refreshToken(async () => {
              await api.get({
                [title]: ids,
              }).then((r) => {
                if (r.data.data.length !== 0) {
                  methods?.set(r.data.data);
                }
              })
            })
          } else {
            await errorMessage(err , '')
          }

          await api.get({
            [title]: ids,
          }).then((r) => {
            if (r.data.data.length !== 0) {
              methods?.set(r.data.data);
            }
          }).catch(async (err) => {
            if(err?.response?.status === 401) {
              await refreshToken(async () => {
                await api.get({
                  [title]: ids,
                }).then((r) => {
                  if (r.data.data.length !== 0) {
                    methods?.set(r.data.data);
                  }
                })
              })
            } else {
              await errorMessage(err , '')
            }
          })
        });
      }  else {
          methods?.set([]);
        }
    } else {
      methods?.set((prev) => {
        if(typeof prev !== 'undefined') {
          const temp = [...prev, ...selectedItemList];

          const parseTemp = removeDuplicates(temp);
          return [...parseTemp];
        } else {
          return selectedItemList;
        }
      });
    }
  }, [selectedItemList]);

  useEffect(() => {
    if(!!defData?.length) {
      setSelectedItemList(defData);
    }
  }, [defData]);

  const renderList = () => {
    if (searchList && searchList.length !== 0) {
      if(fields){
        return searchList.map((el, i) => (
          <div
            key={`search-${title}-list-item-key-${i}`}
            className={s.select_input__search__item}
            onClick={() => addItem(el)}
          >
            {el[fields.name]}
          </div>
        ));
      }
      return searchList.map((el, i) => (
        <div
          key={`search-${title}-list-item-key-${i}`}
          className={s.select_input__search__item}
          onClick={() => addItem(el)}
        >
          {el.title || el.title_ru || el.title_ua}
        </div>
      ));
    }
    return list.length !== 0 && list.map((el, i) => (
      <div
        key={`search-${title}-list-item-key-${i}`}
        className={s.select_input__search__item}
        onClick={() => addItem(el)}
      >
        {el.title || el.title_ru || el.title_ua}
      </div>
    ));
  };

  const handleClick = () => setIsActive(!isActive);
  const handleChange = (e) => {
    const temp = [];
    if (list.length !== 0) {
      list.filter((el) => {
        const title = el?.title_ru?.toLowerCase() || el?.title_ua?.toLowerCase() || el?.title?.toLowerCase();
        if (title.includes(e.target.value.toLowerCase())) {
          temp.push(el);
        }
        return null;
      });
    }
    setSearchList(temp);
  };

  const searchByValue = (e) => {
    if (e.target.value.length !== 0) {
      api.get({
        [title]: e.target.value,
      }).then((r) => {
        setSearchList([]);
        if (r.data.data.length !== 0) setSearchList(r.data.data);
        setIsActive(true);
      }).catch(async (err) => {
        if(err?.response?.status === 401) {
          await refreshToken(async () => {
            await api.get({
              [title]: e.target.value,
            }).then((r) => {
              setSearchList([]);
              if (r.data.data.length !== 0) setSearchList(r.data.data);
              setIsActive(true);
            })
          })
        } else {
          await errorMessage(err , '')
        }
      });
    } else {
      setSearchList([]);
    }
  };

  const handleApiSearch = (e) => {
    if (searchBy !== '') {
      api.get({
        domain_id: domainID,
        [searchBy]: e.target.value,
      }).then((r) => {
        setSearchList([]);
        if (r.data?.length > 0) setSearchList(r.data);
        else if (r.data?.data?.length > 0) setSearchList(r.data.data);
        setIsActive(true);
      }).catch(async (err) => {
        if(err?.response?.status === 401) {
          await refreshToken(async () => {
            await api.get({
              domain_id: domainID,
              [searchBy]: e.target.value,
            }).then((r) => {
              setSearchList([]);
              if (r.data?.length > 0) setSearchList(r.data);
              else if (r.data?.data?.length > 0) setSearchList(r.data.data);
              setIsActive(true);
            })
          })
        } else {
          await errorMessage(err , '')
        }
      });
    } else {
      setSearchList([]);
    }
  };

  const addItem = (el) => {
    const temp = [...selectedItemList];
    const index = temp.indexOf(el);
    if (index.toString() === '-1') {
      temp.push(el);
      setSelectedItemList(temp);
    }
    setIsActive(false);
  };

  const removeItem = (el) => {
    const temp = [...selectedItemList];
    const index = temp.indexOf(el);
    if (index !== -1) {
      temp.splice(index, 1);
      setSelectedItemList(temp);
      methods.set(temp);
    }
  };

  return (
    <div className={s.select_input__container} ref={selectInputRef} style={styleWrapper}>
      <input
        className={className ? className : "form-input"}
        onClick={handleClick}
        onChange={apiSearch ? handleApiSearch : (searchBy === 'value' ? searchByValue : handleChange)}
        id={idInput}
      />
      {isActive && <div className={s.select_input__search}>{renderList()}</div>}
      <div
        className={s.select_input__labels}
      >
        {(selectedItemList && selectedItemList.length !== 0)
          ? selectedItemList.map((el, k) => (
            <div
                style={{
                  display: `${visibleSelected ? 'block' : 'none'}`
                }}
              key={`selected-${name}-list-item-key-${k}`}
              onClick={() => removeItem(el)}
              className={`${s.select_input__label__item} label mb-2`}
            >
              <span>{el[fields?.name] || el.title_ru || el.title_ua || el.title}</span>
            </div>
          ))
          : defVal && defVal.length !== 0 && defVal.map((el, k) => (
            <div
              key={`selected-${name}-list-item-key-${k}`}
              onClick={() => removeItem(el)}
              className={`${s.select_input__label__item} label mb-2`}
            >
              <span>{el.title_ru || el.title_ua || el.title}</span>
            </div>
          ))}
      </div>
    </div>
  );
});

export default SelectInput;
