import {
  DOMAIN_CREATE,
  DOMAIN_GET_EDIT,
  DOMAIN_EDIT,
  DOMAIN_DELETE,
  DOMAINS_GET,
  DOMAIN_GET,
  SELECTIONS_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';
// all
export const getDomains = () => fetchAx({
  url: DOMAINS_GET,
  method: 'GET',
});

export const getDomain = (params) => fetchAx({
  url: DOMAIN_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const createDomain = (data) => fetchAx({
  url: DOMAIN_CREATE,
  method: 'POST',
  data,
});

export const getSelectionsAll = (params) => fetchAx({
  url: SELECTIONS_GET,
  method: 'GET',
  params: {
    per_page: 1000,
    ...params
  }
});

export const getDomainEdit = (id) => fetchAx({
  url: DOMAIN_GET_EDIT.join(id),
  method: 'GET',
});

export const editDomain = (id, data) => fetchAx({
  url: DOMAIN_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeDomain = (id) => fetchAx({
  url: DOMAIN_DELETE.join(id),
  method: 'DELETE',
});
