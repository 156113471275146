// Api domain
export const DOMAIN_API = process.env.DEV ? '' : process.env.REACT_APP_BACKEND_DOMAIN;
export const DOMAIN_CLIENT_API = process.env.DEV ? '' : process.env.REACT_APP_CDN_DOMAIN;
export const TINY_API_KEY = process.env.DEV ? '' : process.env.REACT_APP_TINY_API_KEY;
export const DEVELOPMENT_ENVIRONMENT = process.env.DEV ? '' : process.env.REACT_APP_DEVELOPMENT_ENVIRONMENT;
export const CLIENT_SECRET = process.env.DEV ? '' : process.env.REACT_APP_CLIENT_SECRET;
export const CLIENT_ID = process.env.DEV ? '' : process.env.REACT_APP_CLIENT_ID;

// Files
export const SEND_FILE = `${DOMAIN_API}/api/admin/upload`; // post

// Media
export const GET_MEDIA = `${DOMAIN_API}/api/admin/media`; // get
export const SEND_MEDIA = `${DOMAIN_API}/api/admin/media`; // post
export const SEND_POSITION_MEDIA = `${DOMAIN_API}/api/admin/media/position`; // post
export const EDIT_MEDIA = [`${DOMAIN_API}/api/admin/media/`, '']; // put
export const DELETE_MEDIA = [`${DOMAIN_API}/api/admin/media/`, '']; // delete
export const TYPES_MEDIA = `${DOMAIN_API}/api/admin/media/types`; // get

// domains
export const DOMAINS_GET = `${DOMAIN_API}/api/admin/domain/all`; // get

// banks
export const BANKS_GET = `${DOMAIN_API}/api/admin/bank`; // get
export const BANKS_SELECTIONS = `${DOMAIN_API}/api/admin/bank-selection`; // post
export const BANKS_SELECTIONS_UPDATE = [`${DOMAIN_API}/api/admin/bank-selection/`, '']; // put
export const BANK_SELECTION_EDIT = [`${DOMAIN_API}/api/admin/bank-selection/`, '/edit']; // put
export const BANK_SELECTION_DELETE = [`${DOMAIN_API}/api/admin/bank-selection/`, '']; // delete

//banks/sort
export const BANK_SELECTION_POSITION = [`${DOMAIN_API}/api/admin/bank-selection-sort/`, '/to/']; // delete
export const BANK_SELECTION_UPDATE = `${DOMAIN_API}/api/admin/bank-selection-sort/update`; // delete
export const BANK_SELECTION_REFRESH = `${DOMAIN_API}/api/admin/bank-selection-sort/refresh`; // post

// oAuth2
export const OAUTH_TOKEN_GET = `${DOMAIN_API}/api/oauth/token`; // post

export const ADMIN_PERMISSIONS_GET = `${DOMAIN_API}/api/admin/me`; // get

// countries
export const COUNTRIES_GET = `${DOMAIN_API}/api/admin/country/all`; // get

// city
// TODO: need fix
export const CITY_GET = 'https://dev-samsungshop.dev.org.ua/api/client/warehouse/card?lang=ua';
export const CITY_ADMIN_GET = `${DOMAIN_API}/api/admin/city/all`;

// domains
export const DOMAIN_GET = `${DOMAIN_API}/api/admin/domain/`; // get
export const DOMAIN_CREATE = `${DOMAIN_API}/api/admin/domain`; // post
export const DOMAIN_GET_EDIT = [`${DOMAIN_API}/api/admin/domain/`, '/edit']; // get
export const DOMAIN_EDIT = [`${DOMAIN_API}/api/admin/domain/`, '']; // put
export const DOMAIN_DELETE = [`${DOMAIN_API}/api/admin/domain/`, '']; // delete

// categories
export const CAT_GET = `${DOMAIN_API}/api/admin/category/`; // get
export const CAT_GET_ALL = `${DOMAIN_API}/api/admin/category/all`; // get
export const CAT_GET_SEARCH = `${DOMAIN_API}/api/admin/category/search`; // get
export const CAT_GET_TREE = `${DOMAIN_API}/api/admin/category/get-tree`; // get
export const CAT_CREATE = `${DOMAIN_API}/api/admin/category`; // post
export const CAT_GET_EDIT = [`${DOMAIN_API}/api/admin/category/`, '/edit']; // get
export const CAT_EDIT = [`${DOMAIN_API}/api/admin/category/`, '']; // put
export const CAT_DELETE = [`${DOMAIN_API}/api/admin/category/`, '']; // delete

// group characteristic
export const GROUP_CHARACTERISTIC_GET = `${DOMAIN_API}/api/admin/characteristic-group/`; // get
export const GROUP_CHARACTERISTIC_CREATE = `${DOMAIN_API}/api/admin/characteristic-group`; // post
export const GROUP_CHARACTERISTIC_GET_EDIT = [`${DOMAIN_API}/api/admin/characteristic-group/`, '/edit']; // get
export const GROUP_CHARACTERISTIC_EDIT = [`${DOMAIN_API}/api/admin/characteristic-group/`, '']; // put
export const GROUP_CHARACTERISTIC_DELETE = [`${DOMAIN_API}/api/admin/characteristic-group/`, '']; // delete

// specs
export const SPEC_GET = `${DOMAIN_API}/api/admin/characteristic/`; // get
export const SPEC_CREATE = `${DOMAIN_API}/api/admin/characteristic`; // post
export const SPEC_GET_EDIT = [`${DOMAIN_API}/api/admin/characteristic/`, '/edit']; // get
export const SPEC_EDIT = [`${DOMAIN_API}/api/admin/characteristic/`, '']; // put
export const SPEC_DELETE = [`${DOMAIN_API}/api/admin/characteristic/`, '']; // delete
export const SPEC_SCOPE_GET = `${DOMAIN_API}/api/admin/characteristic-value/scope/all`; // get

// products
export const PRODUCT_GET = `${DOMAIN_API}/api/admin/product/`; // get
export const PRODUCT_GET_SEARCH = `${DOMAIN_API}/api/admin/product/search/`; // get
export const PRODUCT_GET_SEARCH_LIST = `${DOMAIN_API}/api/admin/product/search-list`; // get
export const PRODUCT_CREATE = `${DOMAIN_API}/api/admin/product`; // post
export const PRODUCT_GET_EDIT = [`${DOMAIN_API}/api/admin/product/`, '/edit']; // get
export const PRODUCT_EDIT = [`${DOMAIN_API}/api/admin/product/`, '']; // put
export const PRODUCT_DELETE = [`${DOMAIN_API}/api/admin/product/`, '']; // delete

// product media
export const PRODUCT_MEDIA_GET = [`${DOMAIN_API}/api/admin/product/`, '/media']; // get

// product additional options
export const PRODUCT_ADDITIONAL_OPTIONS_GET = `${DOMAIN_API}/api/admin/product/additional-options`; // get

// product/states
export const PRODUCT_STATES_GET = `${DOMAIN_API}/api/admin/product/states`; // get
export const PRODUCT_DOMAIN_OPTION_DELETE = [`${DOMAIN_API}/api/admin/product/domain-option/`, '']; // delete

// product availability
export const PRODUCT_AVAILABILITY_GET = [`${DOMAIN_API}/api/admin/product/`, '/availability']; // get

// product prices
export const PRODUCT_PRICES_GET = [`${DOMAIN_API}/api/admin/product/`, '/prices']; // get

// product copy characteristics
export const PRODUCT_COPY_CHARACTERISTICS_GET = [`${DOMAIN_API}/api/admin/product/`, '/characteristics']; // get

// product select
export const PRODUCT_SELECT_ALL = `${DOMAIN_API}/api/admin/product/select/all`; // post
export const PRODUCT_SELECT_ALL_DELETE = `${DOMAIN_API}/api/admin/product/select/all`; // delete
export const PRODUCT_SELECT = `${DOMAIN_API}/api/admin/product/select/`; // post
export const PRODUCT_SELECT_APPLY = `${DOMAIN_API}/api/admin/product/select/apply`; // post

// markers
export const MARKER_GET = `${DOMAIN_API}/api/admin/marker/`; // get
export const MARKER_GET_ALL = `${DOMAIN_API}/api/admin/marker/all`; // get
export const MARKER_CREATE = `${DOMAIN_API}/api/admin/marker`; // post
export const MARKER_GET_EDIT = [`${DOMAIN_API}/api/admin/marker/`, '/edit']; // get
export const MARKER_EDIT = [`${DOMAIN_API}/api/admin/marker/`, '']; // put
export const MARKER_DELETE = [`${DOMAIN_API}/api/admin/marker/`, '']; // delete

export const MARKER_TYPES_GET = `${DOMAIN_API}/api/admin/marker/types/`; // get

// warehouse
export const WH_GET = `${DOMAIN_API}/api/admin/warehouse/`; // get
export const WH_CREATE = `${DOMAIN_API}/api/admin/warehouse`; // post
export const WH_GET_EDIT = [`${DOMAIN_API}/api/admin/warehouse/`, '/edit']; // get
export const WH_EDIT = [`${DOMAIN_API}/api/admin/warehouse/`, '']; // put
export const WH_DELETE = [`${DOMAIN_API}/api/admin/warehouse/`, '']; // delete
export const WH_PARCEL_SENDERS_GET = `${DOMAIN_API}/api/admin/warehouse/parcel-senders/`; // get

// quick filter
export const QF_GET = `${DOMAIN_API}/api/admin/quick-filter/`; // get
export const QF_CREATE = `${DOMAIN_API}/api/admin/quick-filter`; // post
export const QF_GET_EDIT = [`${DOMAIN_API}/api/admin/quick-filter/`, '/edit']; // get
export const QF_EDIT = [`${DOMAIN_API}/api/admin/quick-filter/`, '']; // put
export const QF_DELETE = [`${DOMAIN_API}/api/admin/quick-filter/`, '']; // delete

// installment management
export const IM_GET = `${DOMAIN_API}/api/admin/installment/`; // get
export const IM_CREATE = `${DOMAIN_API}/api/admin/installment`; // post
export const IM_GET_EDIT = [`${DOMAIN_API}/api/admin/installment/`, '/edit']; // get
export const IM_EDIT = [`${DOMAIN_API}/api/admin/installment/`, '']; // put
export const IM_DELETE = [`${DOMAIN_API}/api/admin/installment/`, '']; // delete

// banks
export const BANK_GET = `${DOMAIN_API}/api/admin/bank/`; // get
export const BANK_CREATE = `${DOMAIN_API}/api/admin/bank`; // post
export const BANK_GET_EDIT = [`${DOMAIN_API}/api/admin/bank/`, '/edit']; // get
export const BANK_EDIT = [`${DOMAIN_API}/api/admin/bank/`, '']; // put
export const BANK_DELETE = [`${DOMAIN_API}/api/admin/bank/`, '']; // delete
export const BANK_PRODUCT = `${DOMAIN_API}/api/admin/tf-credit-product`; // get

// credit types
export const CREDIT_TYPES_GET = `${DOMAIN_API}/api/admin/credit-type/`; // get
export const CREDIT_TYPE_CREATE = `${DOMAIN_API}/api/admin/credit-type`; // post
export const CREDIT_TYPE_GET_EDIT = [`${DOMAIN_API}/api/admin/credit-type/`, '/edit']; // get
export const CREDIT_TYPE_EDIT = [`${DOMAIN_API}/api/admin/credit-type/`, '']; // put
export const CREDIT_TYPE_DELETE = [`${DOMAIN_API}/api/admin/credit-type/`, '']; // delete

// credit offers
export const CREDIT_OFFERS_GET = `${DOMAIN_API}/api/admin/credit-offer/`; // get
export const CREDIT_OFFER_CREATE = `${DOMAIN_API}/api/admin/credit-offer`; // post
export const CREDIT_OFFER_GET_EDIT = [`${DOMAIN_API}/api/admin/credit-offer/`, '/edit']; // get
export const CREDIT_OFFER_EDIT = [`${DOMAIN_API}/api/admin/credit-offer/`, '']; // put
export const CREDIT_OFFER_DELETE = [`${DOMAIN_API}/api/admin/credit-offer/`, '']; // delete

// promocode
export const PROMO_GET = `${DOMAIN_API}/api/admin/promo-code-list/`; // get
export const PROMO_GET_ALL = `${DOMAIN_API}/api/admin/promo-code-list/all`; // get
export const PROMO_CREATE = `${DOMAIN_API}/api/admin/promo-code-list`; // post
export const PROMO_GET_EDIT = [`${DOMAIN_API}/api/admin/promo-code-list/`, '/edit']; // get
export const PROMO_EDIT = [`${DOMAIN_API}/api/admin/promo-code-list/`, '']; // put
export const PROMO_DELETE = [`${DOMAIN_API}/api/admin/promo-code-list/`, '']; // delete

// spec offer
export const SPEC_OFFER_GET = `${DOMAIN_API}/api/admin/special-offer/`; // get
export const SPEC_OFFER_GET_ALL = `${DOMAIN_API}/api/admin/special-offer/all`; // get
export const SPEC_OFFER_OPTIONS_GET = `${DOMAIN_API}/api/admin/special-offer/options`; // get
export const SPEC_OFFER_GET_BY_ID = [`${DOMAIN_API}/api/admin/special-offer/`, '/products']; // get
export const SPEC_OFFER_CREATE = `${DOMAIN_API}/api/admin/special-offer`; // post
export const SPEC_OFFER_GET_EDIT = [`${DOMAIN_API}/api/admin/special-offer/`, '/edit']; // get
export const SPEC_OFFER_EDIT = [`${DOMAIN_API}/api/admin/special-offer/`, '']; // put
export const SPEC_OFFER_DELETE = [`${DOMAIN_API}/api/admin/special-offer/`, '']; // delete
export const SPEC_OFFER_CALCULATE = `${DOMAIN_API}/api/admin/special-offer/calculate-discount`; // post
export const SPEC_OFFER_PRICE_TYPES = `${DOMAIN_API}/api/admin/special-offer/price-types`; // get
export const SPEC_OFFER_MOVE_TO = (offer, id, move) => `${DOMAIN_API}/api/admin/special-offer/${offer}/products/${id}/to/${move}`; // put

// spec offer banner
export const SPEC_OFFER_BANNER_GET = `${DOMAIN_API}/api/admin/special-offer/banner/`; // get
export const SPEC_OFFER_BANNER_GET_ALL = `${DOMAIN_API}/api/admin/special-offer/banner/all`; // get
export const SPEC_OFFER_BANNER_CREATE = `${DOMAIN_API}/api/admin/special-offer/banner`; // post
export const SPEC_OFFER_BANNER_GET_EDIT = [`${DOMAIN_API}/api/admin/special-offer/banner/`, '/edit']; // get
export const SPEC_OFFER_BANNER_EDIT = [`${DOMAIN_API}/api/admin/special-offer/banner/`, '']; // put
export const SPEC_OFFER_BANNER_DELETE = [`${DOMAIN_API}/api/admin/special-offer/banner/`, '']; // delete

// spec offer banner clone
export const SPEC_OFFER_BANNER_CLONE_GET_EDIT = [`${DOMAIN_API}/api/admin/special-offer/banner/clone/`, '/']; // get

// spec offer banner tag
export const SPEC_OFFER_BANNER_TAG_GET = `${DOMAIN_API}/api/admin/special-offer/banners/tag/`; // get
export const SPEC_OFFER_BANNER_TAG_CREATE = `${DOMAIN_API}/api/admin/special-offer/banners/tag/`; // post
export const SPEC_OFFER_BANNER_TAG_GET_EDIT = [`${DOMAIN_API}/api/admin/special-offer/banners/tag/`, '/edit']; // get
export const SPEC_OFFER_BANNER_TAG_EDIT = [`${DOMAIN_API}/api/admin/special-offer/banners/tag/`, '']; // put
export const SPEC_OFFER_BANNER_TAG_DELETE = [`${DOMAIN_API}/api/admin/special-offer/banners/tag/`, '']; // delete

// promocode series
export const PROMO_SERIES_GET = `${DOMAIN_API}/api/admin/promo-code-series/`; // get
export const PROMO_SERIES_GET_ALL = `${DOMAIN_API}/api/admin/promo-code-series/all`; // get
export const PROMO_SERIES_CREATE = `${DOMAIN_API}/api/admin/promo-code-series`; // post
export const PROMO_SERIES_GET_EDIT = [`${DOMAIN_API}/api/admin/promo-code-series/`, '/edit']; // get
export const PROMO_SERIES_EDIT = [`${DOMAIN_API}/api/admin/promo-code-series/`, '']; // put
export const PROMO_SERIES_DELETE = [`${DOMAIN_API}/api/admin/promo-code-series/`, '']; // delete

// unaccounted products
export const UNACCOUNTED_PRODUCTS_GET = `${DOMAIN_API}/api/admin/unaccounted-product/`; // get
export const UNACCOUNTED_PRODUCTS_GET_ALL = `${DOMAIN_API}/api/admin/unaccounted-product/all`; // get
export const UNACCOUNTED_PRODUCTS_CREATE = `${DOMAIN_API}/api/admin/unaccounted-product`; // post
export const UNACCOUNTED_PRODUCTS_GET_EDIT = [`${DOMAIN_API}/api/admin/unaccounted-product/`, '/edit']; // get
export const UNACCOUNTED_PRODUCTS_EDIT = [`${DOMAIN_API}/api/admin/unaccounted-product/`, '']; // put
export const UNACCOUNTED_PRODUCTS_DELETE = [`${DOMAIN_API}/api/admin/unaccounted-product/`, '']; // delete

// additional-services
export const ADDITIONAL_SERVICE_GET = `${DOMAIN_API}/api/admin/additional-service/`; // get
export const ADDITIONAL_SERVICE_GET_ALL = `${DOMAIN_API}/api/admin/additional-service/all`; // get
export const ADDITIONAL_SERVICE_CREATE = `${DOMAIN_API}/api/admin/additional-service`; // post
export const ADDITIONAL_SERVICE_GET_EDIT = [`${DOMAIN_API}/api/admin/additional-service/`, '/edit']; // get
export const ADDITIONAL_SERVICE_EDIT = [`${DOMAIN_API}/api/admin/additional-service/`, '']; // put
export const ADDITIONAL_SERVICE_DELETE = [`${DOMAIN_API}/api/admin/additional-service/`, '']; // delete

// additional-services
export const ACCESSORY_RELATION_GET = `${DOMAIN_API}/api/admin/accessory-relations/`; // get
export const ACCESSORY_RELATION_GET_ALL = `${DOMAIN_API}/api/admin/accessory-relations/all`; // get
export const ACCESSORY_RELATION_CREATE = `${DOMAIN_API}/api/admin/accessory-relations`; // post
export const ACCESSORY_RELATION_GET_EDIT = [`${DOMAIN_API}/api/admin/accessory-relations/`, '/edit']; // get
export const ACCESSORY_RELATION_GET_PRODUCT_FROM = [`${DOMAIN_API}/api/admin/accessory-relations/`, '/from']; // get
export const ACCESSORY_RELATION_GET_PRODUCT_TO = [`${DOMAIN_API}/api/admin/accessory-relations/`, '/to']; // get
export const ACCESSORY_RELATION_EDIT = [`${DOMAIN_API}/api/admin/accessory-relations/`, '']; // put
export const ACCESSORY_RELATION_DELETE = [`${DOMAIN_API}/api/admin/accessory-relations/`, '']; // delete

// banner home
export const BANNER_MAIN_PAGE_GET = `${DOMAIN_API}/api/admin/banner-main-page/`; // get
export const BANNER_MAIN_PAGE_GET_ALL = `${DOMAIN_API}/api/admin/banner-main-page/all`; // get
export const BANNER_MAIN_PAGE_CREATE = `${DOMAIN_API}/api/admin/banner-main-page`; // post
export const BANNER_MAIN_PAGE_GET_EDIT = [`${DOMAIN_API}/api/admin/banner-main-page/`, '/edit']; // get
export const BANNER_MAIN_PAGE_EDIT = [`${DOMAIN_API}/api/admin/banner-main-page/`, '']; // put
export const BANNER_MAIN_PAGE_DELETE = [`${DOMAIN_API}/api/admin/banner-main-page/`, '']; // delete

// banner menu
export const BANNER_MENU_GET = `${DOMAIN_API}/api/admin/banner-menu/`; // get
export const BANNER_MENU_GET_ALL = `${DOMAIN_API}/api/admin/banner-menu/all`; // get
export const BANNER_MENU_CREATE = `${DOMAIN_API}/api/admin/banner-menu`; // post
export const BANNER_MENU_GET_EDIT = [`${DOMAIN_API}/api/admin/banner-menu/`, '/edit']; // get
export const BANNER_MENU_EDIT = [`${DOMAIN_API}/api/admin/banner-menu/`, '']; // put
export const BANNER_MENU_DELETE = [`${DOMAIN_API}/api/admin/banner-menu/`, '']; // delete

// banner catalog
export const BANNER_CATALOG_GET = `${DOMAIN_API}/api/admin/banner-catalog/`; // get
export const BANNER_CATALOG_GET_ALL = `${DOMAIN_API}/api/admin/banner-catalog/all`; // get
export const BANNER_CATALOG_CREATE = `${DOMAIN_API}/api/admin/banner-catalog`; // post
export const BANNER_CATALOG_GET_EDIT = [`${DOMAIN_API}/api/admin/banner-catalog/`, '/edit']; // get
export const BANNER_CATALOG_EDIT = [`${DOMAIN_API}/api/admin/banner-catalog/`, '']; // put
export const BANNER_CATALOG_DELETE = [`${DOMAIN_API}/api/admin/banner-catalog/`, '']; // delete

// banner catalog horizontal
export const BANNER_CATALOG_HORIZONTAL_GET = `${DOMAIN_API}/api/admin/banner-catalog-horizontal/`; // get
export const BANNER_CATALOG_HORIZONTAL_CREATE = `${DOMAIN_API}/api/admin/banner-catalog-horizontal`; // post
export const BANNER_CATALOG_HORIZONTAL_GET_EDIT = [`${DOMAIN_API}/api/admin/banner-catalog-horizontal/`, '/edit']; // get
export const BANNER_CATALOG_HORIZONTAL_EDIT = [`${DOMAIN_API}/api/admin/banner-catalog-horizontal/`, '']; // put
export const BANNER_CATALOG_HORIZONTAL_DELETE = [`${DOMAIN_API}/api/admin/banner-catalog-horizontal/`, '']; // delete

// banner head
export const BANNER_HEAD_GET = `${DOMAIN_API}/api/admin/banner-header/`; // get
export const BANNER_HEAD_GET_ALL = `${DOMAIN_API}/api/admin/banner-header/all`; // get
export const BANNER_HEAD_CREATE = `${DOMAIN_API}/api/admin/banner-header`; // post
export const BANNER_HEAD_GET_EDIT = [`${DOMAIN_API}/api/admin/banner-header/`, '/edit']; // get
export const BANNER_HEAD_EDIT = [`${DOMAIN_API}/api/admin/banner-header/`, '']; // put
export const BANNER_HEAD_DELETE = [`${DOMAIN_API}/api/admin/banner-header/`, '']; // delete

// benefits
export const BENEFIT_GET = `${DOMAIN_API}/api/admin/benefit/`; // get
export const BENEFIT_GET_ALL = `${DOMAIN_API}/api/admin/benefit/all`; // get
export const BENEFIT_CREATE = `${DOMAIN_API}/api/admin/benefit`; // post
export const BENEFIT_GET_EDIT = [`${DOMAIN_API}/api/admin/benefit/`, '/edit']; // get
export const BENEFIT_EDIT = [`${DOMAIN_API}/api/admin/benefit/`, '']; // put
export const BENEFIT_DELETE = [`${DOMAIN_API}/api/admin/benefit/`, '']; // delete

// popular goods
export const POPULAR_GOOD_GET_ALL = `${DOMAIN_API}/api/admin/popularity-block/all`; // get
export const POPULAR_GOOD_CREATE = `${DOMAIN_API}/api/admin/popularity-block`; // post
export const POPULAR_GOOD_GET_EDIT = [`${DOMAIN_API}/api/admin/popularity-block/`, '/edit']; // get
export const POPULAR_GOOD_EDIT = [`${DOMAIN_API}/api/admin/popularity-block/`, '']; // put
export const POPULAR_GOOD_DELETE = [`${DOMAIN_API}/api/admin/popularity-block/`, '']; // delete

// unique services
export const UNIQUE_SERVICE_GET = `${DOMAIN_API}/api/admin/services-block/`; // get
export const UNIQUE_SERVICE_GET_ALL = `${DOMAIN_API}/api/admin/services-block/all`; // get
export const UNIQUE_SERVICE_CREATE = `${DOMAIN_API}/api/admin/services-block`; // post
export const UNIQUE_SERVICE_GET_EDIT = [`${DOMAIN_API}/api/admin/services-block/`, '/edit']; // get
export const UNIQUE_SERVICE_EDIT = [`${DOMAIN_API}/api/admin/services-block/`, '']; // put
export const UNIQUE_SERVICE_DELETE = [`${DOMAIN_API}/api/admin/services-block/`, '']; // delete

// newest
export const NEWEST_GET = `${DOMAIN_API}/api/admin/newest-block/`; // get
export const NEWEST_GET_ALL = `${DOMAIN_API}/api/admin/newest-block/all`; // get
export const NEWEST_CREATE = `${DOMAIN_API}/api/admin/newest-block`; // post
export const NEWEST_GET_EDIT = [`${DOMAIN_API}/api/admin/newest-block/`, '/edit']; // get
export const NEWEST_EDIT = [`${DOMAIN_API}/api/admin/newest-block/`, '']; // put
export const NEWEST_DELETE = [`${DOMAIN_API}/api/admin/newest-block/`, '']; // delete

// popular categories
export const POPULAR_CATEGORY_GET = `${DOMAIN_API}/api/admin/main-page-category-block/`; // get
export const POPULAR_CATEGORY_GET_ALL = `${DOMAIN_API}/api/admin/main-page-category-block/all`; // get
export const POPULAR_CATEGORY_CREATE = `${DOMAIN_API}/api/admin/main-page-category-block`; // post
export const POPULAR_CATEGORY_GET_EDIT = [`${DOMAIN_API}/api/admin/main-page-category-block/`, '/edit']; // get
export const POPULAR_CATEGORY_EDIT = [`${DOMAIN_API}/api/admin/main-page-category-block/`, '']; // put
export const POPULAR_CATEGORY_DELETE = [`${DOMAIN_API}/api/admin/main-page-category-block/`, '']; // delete

// services
export const SERVICE_GET = `${DOMAIN_API}/api/admin/main-page-service/`; // get
export const SERVICE_GET_ALL = `${DOMAIN_API}/api/admin/main-page-service/all`; // get
export const SERVICE_CREATE = `${DOMAIN_API}/api/admin/main-page-service`; // post
export const SERVICE_GET_EDIT = [`${DOMAIN_API}/api/admin/main-page-service/`, '/edit']; // get
export const SERVICE_EDIT = [`${DOMAIN_API}/api/admin/main-page-service/`, '']; // put
export const SERVICE_DELETE = [`${DOMAIN_API}/api/admin/main-page-service/`, '']; // delete

// categories menu
export const CATEGORIES_MENU_GET = `${DOMAIN_API}/api/admin/menu/`; // get
export const CATEGORIES_MENU_CREATE = `${DOMAIN_API}/api/admin/menu`; // post
export const CATEGORIES_MENU_GET_EDIT = [`${DOMAIN_API}/api/admin/menu/`, '/edit']; // get
export const CATEGORIES_MENU_EDIT = [`${DOMAIN_API}/api/admin/menu/`, '']; // put
export const CATEGORIES_MENU_DELETE = [`${DOMAIN_API}/api/admin/menu/`, '']; // delete

// top and bottom menu
export const TOP_BOT_MENU_GET = `${DOMAIN_API}/api/admin/top-bot-menu/`; // get
export const TOP_BOT_MENU_CREATE = `${DOMAIN_API}/api/admin/top-bot-menu`; // post
export const TOP_BOT_MENU_GET_EDIT = [`${DOMAIN_API}/api/admin/top-bot-menu/`, '/edit']; // get
export const TOP_BOT_MENU_EDIT = [`${DOMAIN_API}/api/admin/top-bot-menu/`, '']; // put
export const TOP_BOT_MENU_DELETE = [`${DOMAIN_API}/api/admin/top-bot-menu/`, '']; // delete

// in carts
export const IN_CARTS_GET = `${DOMAIN_API}/api/admin/incart`; // get
export const IN_CARTS_GET_EDIT = [`${DOMAIN_API}/api/admin/incart/`, '/edit']; // get
export const IN_CARTS_EDIT = [`${DOMAIN_API}/api/admin/incart/`, '']; // put
export const IN_CARTS_ABANDON_GET_EDIT = [`${DOMAIN_API}/api/admin/incart/`, '/abandon']; // post
export const CANCEL_VARIANTS_GET = `${DOMAIN_API}/api/admin/cancel-variant/`; // get
export const CANCEL_VARIANTS_CREATE = `${DOMAIN_API}/api/admin/cancel-variant`; // post
export const CANCEL_VARIANTS_GET_EDIT = [`${DOMAIN_API}/api/admin/cancel-variant/`, '/edit']; // get
export const CANCEL_VARIANTS_EDIT = [`${DOMAIN_API}/api/admin/cancel-variant/`, '']; // put
export const CANCEL_VARIANTS_DELETE = [`${DOMAIN_API}/api/admin/cancel-variant/`, '']; // delete

// product feedback
export const PRODUCT_FEEDBACK_GET = `${DOMAIN_API}/api/admin/product-feedback/`; // get
export const PRODUCT_FEEDBACK_CREATE = `${DOMAIN_API}/api/admin/product-feedback`; // post
export const PRODUCT_FEEDBACK_GET_EDIT = [`${DOMAIN_API}/api/admin/product-feedback/`, '/edit']; // get
export const PRODUCT_FEEDBACK_EDIT = [`${DOMAIN_API}/api/admin/product-feedback/`, '']; // put
export const PRODUCT_FEEDBACK_DELETE = [`${DOMAIN_API}/api/admin/product-feedback/`, '']; // delete
export const PRODUCT_FEEDBACK_CREATE_ORDER = [`${DOMAIN_API}/api/admin/product-feedback/`, '/create-order']; // post

// product consultation
export const PRODUCT_CONSULTATION_GET = `${DOMAIN_API}/api/admin/consultations/registrations`; // get
export const PRODUCT_CONSULTATION_CREATE = `${DOMAIN_API}/api/admin/consultations/registrations`; // post
export const PRODUCT_CONSULTATION_GET_EDIT = [`${DOMAIN_API}/api/admin/consultations/registrations/`, '/edit']; // get
export const PRODUCT_CONSULTATION_EDIT = [`${DOMAIN_API}/api/admin/consultations/registrations/`, '']; // put
export const PRODUCT_CONSULTATION_DELETE = [`${DOMAIN_API}/api/admin/consultations/registrations/`, '']; // delete
// export const PRODUCT_CONSULTATION_GET = `${DOMAIN_API}/api/admin/product-consultation/`; // get
// export const PRODUCT_CONSULTATION_CREATE = `${DOMAIN_API}/api/admin/product-consultation`; // post
// export const PRODUCT_CONSULTATION_GET_EDIT = [`${DOMAIN_API}/api/admin/product-consultation/`, '/edit']; // get
// export const PRODUCT_CONSULTATION_EDIT = [`${DOMAIN_API}/api/admin/product-consultation/`, '']; // put
// export const PRODUCT_CONSULTATION_DELETE = [`${DOMAIN_API}/api/admin/product-consultation/`, '']; // delete

// product b2b
export const PRODUCT_B2B_GET = `${DOMAIN_API}/api/admin/product-b2b/`; // get
export const PRODUCT_B2B_CREATE = `${DOMAIN_API}/api/admin/product-b2b`; // post
export const PRODUCT_B2B_GET_EDIT = [`${DOMAIN_API}/api/admin/product-b2b/`, '/edit']; // get
export const PRODUCT_B2B_EDIT = [`${DOMAIN_API}/api/admin/product-b2b/`, '']; // put
export const PRODUCT_B2B_DELETE = [`${DOMAIN_API}/api/admin/product-b2b/`, '']; // delete

// permissions
export const PERMISSIONS_GET = `${DOMAIN_API}/api/admin/permissions?lang=ru`; // get
export const PERMISSION_WATCHED_EDIT = [`${DOMAIN_API}/api/admin/permissions/`, '/watched']; // put
export const PERMISSIONS_TREE_GET = `${DOMAIN_API}/api/admin/permissions/tree`; // get
export const PERMISSIONS_DELETE = [`${DOMAIN_API}/api/admin/permissions/`, '']; // delete

export const PERMISSIONS_CLIENT_GET = `${DOMAIN_API}/api/admin/permissions?lang=ru`; // get
export const PERMISSIONS_CLIENT_DELETE = [`${DOMAIN_API}/api/admin/permissions/`, '']; // delete

// roles
export const ROLES_GET = `${DOMAIN_API}/api/admin/roles/`; // get
export const ROLES_CREATE = `${DOMAIN_API}/api/admin/roles`; // post
export const ROLES_GET_EDIT = [`${DOMAIN_API}/api/admin/roles/`, '/edit']; // get
export const ROLES_EDIT = [`${DOMAIN_API}/api/admin/roles/`, '']; // put
export const ROLES_DELETE = [`${DOMAIN_API}/api/admin/roles/`, '']; // delete

// feeds
export const FEEDS_GET = `${DOMAIN_API}/api/admin/product-feed-settings/`; // get
export const FEED_TYPES_GET = `${DOMAIN_API}/api/admin/product-feed-settings/feed-types/`; // get
export const FEED_CREATE = `${DOMAIN_API}/api/admin/product-feed-settings`; // post
export const FEED_GET_EDIT = [`${DOMAIN_API}/api/admin/product-feed-settings/`, '/edit']; // get
export const FEED_EDIT = [`${DOMAIN_API}/api/admin/product-feed-settings/`, '']; // put
export const FEED_DELETE = [`${DOMAIN_API}/api/admin/product-feed-settings/`, '']; // delete

// orders
export const ORDERS_GET = `${DOMAIN_API}/api/admin/order/`; // get
export const ORDER_CREATE = `${DOMAIN_API}/api/admin/order`; // post
export const ORDER_GET_EDIT = [`${DOMAIN_API}/api/admin/order/`, '/edit']; // get
export const ORDER_EDIT = [`${DOMAIN_API}/api/admin/order/`, '']; // put
export const ORDER_DELETE = [`${DOMAIN_API}/api/admin/order/`, '']; // delete
export const ORDER_PROMO_CODE_STUDENT_EDIT = [`${DOMAIN_API}/api/admin/order/`, '/add-coupon-student']; // post
export const ORDER_PROMO_CODE_EDIT = [`${DOMAIN_API}/api/admin/order/`, '/add-coupon']; // post
export const ORDER_PROMO_CODE_DELETE = [`${DOMAIN_API}/api/admin/order/`, '/delete-coupon']; // delete
export const ORDER_WRIT_OFF_BONUSES = [`${DOMAIN_API}/api/admin/order/`, '/bpm-discount']; // post
export const ORDER_SPLIT = [`${DOMAIN_API}/api/admin/order/`, '/split']; // put
export const ORDER_DOCUMENTS_GET = [`${DOMAIN_API}/api/admin/order/`, '/document']; // get
export const ORDER_VISIT_GET = [`${DOMAIN_API}/api/admin/visit/check/order/`, '']; // get
export const ORDERS_SOURCES_GET = `${DOMAIN_API}/api/admin/order/sources`; // get
export const ORDER_SUBORDER_SERVICE = [`${DOMAIN_API}/api/admin/order/`, '/suborder']; // post
export const ORDER_SIMILAR_GET_EDIT = [`${DOMAIN_API}/api/admin/order/`, '/similar']; // get
export const ORDER_GET_BINOTEL_CALLS = [`${DOMAIN_API}/api/admin/binotel/`, '']; // get
export const ORDER_EXCEL_MODES_GET = `${DOMAIN_API}/api/admin/order/excel-modes/`; // get

//order clone
export const ORDER_CLONE_CREATE = [`${DOMAIN_API}/api/admin/order/`, '/clone']; // post

//order product services
export const ORDER_SERVICES_GET = (id, orderProductId) => `${DOMAIN_API}/api/admin/order/${id}/products/${orderProductId}/additional-services`; // get

//order delivery
export const DELIVERY_SETTLEMENTS_GET = `${DOMAIN_API}/api/admin/order/delivery/settlements`; // get
export const DELIVERY_TYPES_GET = `${DOMAIN_API}/api/admin/order/delivery/delivery-types`; // get
export const DELIVERY_TIME_INTERVALS_GET = `${DOMAIN_API}/api/admin/order/delivery/time-intervals`; // get
export const DELIVERY_OFFICES_GET = `${DOMAIN_API}/api/admin/order/delivery/offices`; // get
export const DELIVERY_TERMINALS_GET = `${DOMAIN_API}/api/admin/order/delivery/terminals`; // get
export const DELIVERY_STREETS_GET = `${DOMAIN_API}/api/admin/order/delivery/streets`; // get
export const DELIVERY_STATUSES_GET = `${DOMAIN_API}/api/admin/deliveries/statuses`; // get

// order receipt
export const ORDER_RECEIPT_GET = [`${DOMAIN_API}/api/admin/order/`, '/receipt/log']; // get
export const ORDER_RECEIPT_STATUS_EDIT = (orderId, receiptId) => `${DOMAIN_API}/api/admin/order/${orderId}/receipt/${receiptId}/status`; // put
export const ORDER_RECEIPT_CREATE = [`${DOMAIN_API}/api/admin/order/`, '/receipt/create']; // post
export const ORDER_RECEIPT_RETURN = [`${DOMAIN_API}/api/admin/order/`, '/receipt/return']; // post

// order invoice
export const ORDER_INVOICE = [`${DOMAIN_API}/api/admin/order/`, '/invoice']; // post

// order coupon-visa
export const ORDER_COUPON_VISA = [`${DOMAIN_API}/api/admin/order/`, '/apply-discount-visa']; // put
export const ORDER_COUPON_VISA_DELETE = [`${DOMAIN_API}/api/admin/order/`, '/cancel-discount-visa']; // delete

// order coupon-pks
export const ORDER_COUPON_PKS = [`${DOMAIN_API}/api/admin/order/`, '/apply-coupon-pks']; // put
export const ORDER_COUPON_PKS_DELETE = [`${DOMAIN_API}/api/admin/order/`, '/cancel-coupon-pks']; // delete

// ttn
export const SHIPPING_ACTIONS_GET = [`${DOMAIN_API}/api/admin/order/`, '/shipping-actions']; // get
export const CREATE_TTN = [`${DOMAIN_API}/api/admin/order/`, '/shipping-actions']; // post

// invoice
export const PAYMENT_PROVIDERS_GET = [`${DOMAIN_API}/api/admin/order/`, '/invoice/payment-providers']; // get

// sms
export const SMS_GET = `${DOMAIN_API}/api/admin/notifier/types/`; // get
export const SMS_SEND = `${DOMAIN_API}/api/admin/notifier/send/`; // post

// shift
export const SHIFT_STATUS_GET = `${DOMAIN_API}/api/admin/shift/`; // get
export const SHIFT_OPEN = `${DOMAIN_API}/api/admin/shift/`; // post
export const SHIFT_CLOSE = `${DOMAIN_API}/api/admin/shift/`; // delete

// template
export const TEMPLATE_GET = `${DOMAIN_API}/api/admin/template`; // get
export const TEMPLATE_CREATE = `${DOMAIN_API}/api/admin/template`; // post
export const TEMPLATE_GET_EDIT = [`${DOMAIN_API}/api/admin/template/`, '/edit']; // get
export const TEMPLATE_EDIT = [`${DOMAIN_API}/api/admin/template/`, '']; // get
export const TEMPLATE_DELETE = [`${DOMAIN_API}/api/admin/template/`, '']; // delete

// users client
export const USERS_GET = `${DOMAIN_API}/api/admin/user?role=client`;
export const USER_CREATE = `${DOMAIN_API}/api/admin/user`;
export const USERS_GET_EDIT = [`${DOMAIN_API}/api/admin/user/`, '/edit'];
export const USER_EDIT = [`${DOMAIN_API}/api/admin/user/`, ''];
export const USER_DELETE = [`${DOMAIN_API}/api/admin/user/`, ''];

// users admin
export const ADMINS_GET = `${DOMAIN_API}/api/admin/admins`;
export const ADMIN_CREATE = `${DOMAIN_API}/api/admin/admins`;
export const ADMIN_GET_EDIT = [`${DOMAIN_API}/api/admin/admins/`, '/edit'];
export const ADMIN_EDIT = [`${DOMAIN_API}/api/admin/admins/`, ''];
export const ADMIN_DELETE = [`${DOMAIN_API}/api/admin/admins/`, ''];
export const ADMIN_STATUSES_GET = `${DOMAIN_API}/api/admin/statuses`;

// auth
export const LOGIN_USER =  `${DOMAIN_API}/api/admin/login`; //post
export const RESET_PASSWORD = `${DOMAIN_API}/api/admin/email-reset-password`; //post
export const POST_NEW_PASSWORD = `${DOMAIN_API}/api/admin/reset-password`; //post
export const LOGOUT_USER = `${DOMAIN_API}/api/admin/logout`; //post

// payments
export const PAYMENTS_GET = `${DOMAIN_API}/api/admin/payment/`; // get
export const PAYMENT_GET_EDIT = [`${DOMAIN_API}/api/admin/payment/`, '/edit']; // get
export const PAYMENT_EDIT = [`${DOMAIN_API}/api/admin/payment/`, '']; // put

// delivery
export const DELIVERIES_GET = `${DOMAIN_API}/api/admin/delivery/`; // get
export const DELIVERIES_PROVIDERS_GET = `${DOMAIN_API}/api/admin/delivery/providers/`; // get
export const DELIVERY_GET_EDIT = [`${DOMAIN_API}/api/admin/delivery/`, '/edit']; // get
export const DELIVERY_EDIT = [`${DOMAIN_API}/api/admin/delivery/`, '']; // put

// contacts
export const CONTACTS_GET = `${DOMAIN_API}/api/admin/static-page-contact/`; // get
export const CONTACT_CREATE = `${DOMAIN_API}/api/admin/static-page-contact`; // post

// email subscription
export const EMAIL_SUBSCRIPTION_GET = `${DOMAIN_API}/api/admin/email-subscription`; // get
export const EMAIL_SUBSCRIPTION_SOURCES_GET = `${DOMAIN_API}/api/admin/email-subscription/sources/`; // get

// status
export const STATUSES_GET = `${DOMAIN_API}/api/admin/status`; // get
export const STATUS_CREATE = `${DOMAIN_API}/api/admin/status`; // post
export const STATUS_GET_EDIT = [`${DOMAIN_API}/api/admin/status/`, '/edit']; // get
export const STATUS_EDIT = [`${DOMAIN_API}/api/admin/status/`, '']; // get
export const STATUS_DELETE = [`${DOMAIN_API}/api/admin/status/`, '']; // delete

// status_change
export const STATUS_CHANGE_GET = `${DOMAIN_API}/api/admin/status-change`; // get
export const STATUS_CHANGE_CREATE = `${DOMAIN_API}/api/admin/status-change`; // post
export const STATUS_CHANGE_GET_EDIT = [`${DOMAIN_API}/api/admin/status-change/`, '/edit']; // get
export const STATUS_CHANGE_EDIT = [`${DOMAIN_API}/api/admin/status-change/`, '']; // get
export const STATUS_CHANGE_DELETE = [`${DOMAIN_API}/api/admin/status-change/`, '']; // delete

// redirects
export const REDIRECTS_GET = `${DOMAIN_API}/api/admin/seo-redirect`; // get
export const REDIRECT_CREATE = `${DOMAIN_API}/api/admin/seo-redirect`; // post
export const REDIRECT_GET_EDIT = [`${DOMAIN_API}/api/admin/seo-redirect/`, '/edit']; // get
export const REDIRECT_EDIT = [`${DOMAIN_API}/api/admin/seo-redirect/`, '']; // get
export const REDIRECT_DELETE = [`${DOMAIN_API}/api/admin/seo-redirect/`, '']; // delete

// seo_meta
export const SEO_META_GET = `${DOMAIN_API}/api/admin/seo-meta`; // get
export const SEO_META_CREATE = `${DOMAIN_API}/api/admin/seo-meta`; // post
export const SEO_META_GET_EDIT = [`${DOMAIN_API}/api/admin/seo-meta/`, '/edit']; // get
export const SEO_META_EDIT = [`${DOMAIN_API}/api/admin/seo-meta/`, '']; // get
export const SEO_META_DELETE = [`${DOMAIN_API}/api/admin/seo-meta/`, '']; // delete

// purchase
export const PURCHASE_GET = `${DOMAIN_API}/api/admin/purchase`; // get
export const PURCHASE_SEND = `${DOMAIN_API}/api/admin/purchase`; // post

// consultations/managers
export const CONSULTATIONS_MANAGERS_GET = `${DOMAIN_API}/api/admin/consultations/managers`; // get
export const CONSULTATIONS_MANAGER_CREATE = `${DOMAIN_API}/api/admin/consultations/managers`; // post
export const CONSULTATIONS_MANAGER_GET_EDIT = [`${DOMAIN_API}/api/admin/consultations/managers/`, '']; // get
export const CONSULTATIONS_MANAGER_EDIT = [`${DOMAIN_API}/api/admin/consultations/managers/`, '']; // get
export const CONSULTATIONS_MANAGER_DELETE = [`${DOMAIN_API}/api/admin/consultations/managers/`, '']; // delete

// consultations/topics
export const CONSULTATIONS_TOPICS_GET = `${DOMAIN_API}/api/admin/consultations/topics`; // get
export const CONSULTATIONS_TOPIC_CREATE = `${DOMAIN_API}/api/admin/consultations/topics`; // post
export const CONSULTATIONS_TOPIC_GET_EDIT = [`${DOMAIN_API}/api/admin/consultations/topics/`, '']; // get
export const CONSULTATIONS_TOPIC_EDIT = [`${DOMAIN_API}/api/admin/consultations/topics/`, '']; // get
export const CONSULTATIONS_TOPIC_DELETE = [`${DOMAIN_API}/api/admin/consultations/topics/`, '']; // delete

// cart_rules
export const CART_RULES_GET = `${DOMAIN_API}/api/admin/cart-rules`; // get
export const CART_RULE_CREATE = `${DOMAIN_API}/api/admin/cart-rules`; // post
export const CART_RULE_GET_EDIT = [`${DOMAIN_API}/api/admin/cart-rules/`, '']; // get
export const CART_RULE_EDIT = [`${DOMAIN_API}/api/admin/cart-rules/`, '']; // get
export const CART_RULE_DELETE = [`${DOMAIN_API}/api/admin/cart-rules/`, '']; // delete

export const CART_RULE_COUPONS_CREATE = `${DOMAIN_API}/api/admin/cart-rule-coupons/`; // post
export const CART_RULE_COUPONS_DELETE = [`${DOMAIN_API}/api/admin/cart-rule-coupons/`, '']; // delete

// cart_rules/action-types
export const CART_RULES_ACTION_TYPES_GET = `${DOMAIN_API}/api/admin/cart-rules/action-types`; // get

// cart_rules/conditions
export const CART_RULES_CONDITIONS_GET = `${DOMAIN_API}/api/admin/cart-rules/conditions`; // get

// cart_rules/{id}/coupons
export const CART_RULES_COUPONS_GET = [`${DOMAIN_API}/api/admin/cart-rules/`, '/coupons']; // get

// cart_rules/{id}/coupons/create
export const CART_RULES_COUPONS_CREATE_GET = [`${DOMAIN_API}/api/admin/cart-rules/`, '/coupons/create']; // get

// trade in
export const TRADE_IN_GET = `${DOMAIN_API}/api/admin/trade-in`;

// subscribers
export const SUBSCRIBERS_GET = `${DOMAIN_API}/api/admin/subscribers/`; // get
export const SUBSCRIBERS_TYPES_GET = `${DOMAIN_API}/api/admin/subscribers/types/`; // get
export const SUBSCRIBER_CREATE = `${DOMAIN_API}/api/admin/subscribers`; // post
export const SUBSCRIBER_GET_EDIT = [`${DOMAIN_API}/api/admin/subscribers/`, '/edit']; // get
export const SUBSCRIBER_EDIT = [`${DOMAIN_API}/api/admin/subscribers/`, '']; // put
export const SUBSCRIBER_DELETE = [`${DOMAIN_API}/api/admin/subscribers/`, '']; // delete

// subscriber-events
export const SUBSCRIBERS_EVENTS_GET = `${DOMAIN_API}/api/admin/subscriber-types`; // get
export const SUBSCRIBERS_EVENTS_CREATE = `${DOMAIN_API}/api/admin/subscriber-types`; // post

// articles
export const ARTICLES_GET = `${DOMAIN_API}/api/admin/articles/`;
export const ARTICLE_CREATE = `${DOMAIN_API}/api/admin/articles/`;
export const ARTICLE_GET_EDIT = [`${DOMAIN_API}/api/admin/articles/`, '/'];
export const ARTICLE_EDIT = [`${DOMAIN_API}/api/admin/articles/`, ''];
export const ARTICLE_DELETE = [`${DOMAIN_API}/api/admin/articles/`, ''];
export const ARTICLE_GET_TYPES = `${DOMAIN_API}/api/admin/articles/types`;
export const ARTICLE_GET_STATUS  = `${DOMAIN_API}/api/admin/articles/status`;

// brands
export const BRANDS_GET = `${DOMAIN_API}/api/admin/brand/`; // get
export const BRAND_CREATE = `${DOMAIN_API}/api/admin/brand/`; // post
export const BRAND_GET_EDIT = [`${DOMAIN_API}/api/admin/brand/`, '/edit']; // get
export const BRAND_EDIT = [`${DOMAIN_API}/api/admin/brand/`, '']; // get
export const BRAND_DELETE = [`${DOMAIN_API}/api/admin/brand/`, '']; // delete

// reviews
export const REVIEWS_GET = `${DOMAIN_API}/api/admin/social/reviews/`; // get
export const REVIEWS_GET_EDIT = [`${DOMAIN_API}/api/admin/social/reviews/`, '']; // get
export const REVIEWS_EDIT = [`${DOMAIN_API}/api/admin/social/reviews/`, '']; // put
export const REVIEWS_DELETE = [`${DOMAIN_API}/api/admin/social/reviews/`, '']; // delete
export const REVIEWS_STATUSES_GET = `${DOMAIN_API}/api/admin/social/review/statuses`; // get
export const REVIEWS_VISIT_GET = [`${DOMAIN_API}/api/admin/visit/check/review/`, '']; // get

// try and buy
export const TRY_AND_BUY_GET = `${DOMAIN_API}/api/admin/test-drive/`; // get
export const TRY_AND_BUY_CREATE = `${DOMAIN_API}/api/admin/test-drive/`; // post
export const TRY_AND_BUY_GET_EDIT = [`${DOMAIN_API}/api/admin/test-drive/`, '/edit']; // get
export const TRY_AND_BUY_EDIT = [`${DOMAIN_API}/api/admin/test-drive/`, '']; // put
export const TRY_AND_BUY_STATUSES_GET = `${DOMAIN_API}/api/admin/test-drive/statuses`; // get
export const TRY_AND_BUY_SHOPS_GET = `${DOMAIN_API}/api/admin/test-drive/shops`; // get
export const TRY_AND_BUY_DEVICES_GET = `${DOMAIN_API}/api/admin/test-drive/devices`; // get

//quizzes
export const QUIZZES_GET = `${DOMAIN_API}/api/admin/quizzes`; // get

// cooperation b2b
export const COOPERATION_B2B_GET = `${DOMAIN_API}/api/admin/application-forms/`; // get
export const COOPERATION_B2B_CREATE = `${DOMAIN_API}/api/admin/application-forms/`; // post
export const COOPERATION_B2B_GET_EDIT = [`${DOMAIN_API}/api/admin/application-forms/`, '/edit']; // get
export const COOPERATION_B2B_EDIT = [`${DOMAIN_API}/api/admin/application-forms/`, '']; // put
export const COOPERATION_B2B_STATUSES_GET = `${DOMAIN_API}/api/admin/application-forms/statuses`; // get
export const COOPERATION_B2B_TYPES_GET = `${DOMAIN_API}/api/admin/application-forms/types`; // get

// audit
export const AUDIT_GET = `${DOMAIN_API}/api/admin/audit/`; // get

// loyalty
export const LOYALTY_BALANCE_GET = [`${DOMAIN_API}/api/admin/loyalty/user/balance/`, '']; // get
export const LOYALTY_HISTORY_GET = [`${DOMAIN_API}/api/admin/loyalty/user/history/`, '']; // get

// easy pro
export const EASY_PRO_GET = `${DOMAIN_API}/api/admin/easy-pro`; // get
export const EASY_PRO_SHOPS_GET = `${DOMAIN_API}/api/admin/easy-pro/shops`; // get

// brands
export const HR_DOMAIN_MASK_GET = `${DOMAIN_API}/api/admin/hr-domain-mask/`; // get
export const HR_DOMAIN_MASK_CREATE = `${DOMAIN_API}/api/admin/hr-domain-mask/`; // post
export const HR_DOMAIN_MASK_GET_EDIT = [`${DOMAIN_API}/api/admin/hr-domain-mask/`, '']; // get
export const HR_DOMAIN_MASK_EDIT = [`${DOMAIN_API}/api/admin/hr-domain-mask/`, '']; // get
export const HR_DOMAIN_MASK_DELETE = [`${DOMAIN_API}/api/admin/hr-domain-mask/`, '']; // delete

// notice
export const NOTICES_GET = `${DOMAIN_API}/api/admin/me/notices`; // get

// sort/products
export const SORT_PRODUCTS_GET = `${DOMAIN_API}/api/admin/sort/products`; // get
export const SORT_PRODUCTS_REFRESH = `${DOMAIN_API}/api/admin/sort/products/refresh`; // post
export const SORT_PRODUCTS_UPDATE = `${DOMAIN_API}/api/admin/sort/products/update`; // put
export const SORT_PRODUCT_TO_POST = [`${DOMAIN_API}/api/admin/sort/products/`, '/to/']; // post
export const SORT_PRODUCT_DELETE = [`${DOMAIN_API}/api/admin/sort/products/`, '']; // delete

// sort/banners/types
export const SORT_BANNERS_TYPES_GET = `${DOMAIN_API}/api/admin/sort/banners/types`; // get

// sort/banner-catalog
export const SORT_BANNER_CATALOG_GET = `${DOMAIN_API}/api/admin/sort/banner-catalog`; // get
export const SORT_BANNER_CATALOG_REFRESH = `${DOMAIN_API}/api/admin/sort/banner-catalog/refresh`; // post
export const SORT_BANNER_CATALOG_UPDATE = `${DOMAIN_API}/api/admin/sort/banner-catalog/update`; // put
export const SORT_BANNER_CATALOG_TO_POST = [`${DOMAIN_API}/api/admin/sort/banner-catalog/`, '/to/']; // post
export const SORT_BANNER_CATALOG_DELETE = [`${DOMAIN_API}/api/admin/sort/banner-catalog/`, '']; // delete
export const SORT_BANNER_CATALOG_PAGE_POSITION = [`${DOMAIN_API}/api/admin/sort/banner-catalog/`, '/to/']; // delete

// sort/banner-catalog-horizontal
export const SORT_BANNER_CATALOG_HORIZONTAL_GET = `${DOMAIN_API}/api/admin/sort/banner-catalog-horizontal`; // get
export const SORT_BANNER_CATALOG_HORIZONTAL_REFRESH = `${DOMAIN_API}/api/admin/sort/banner-catalog-horizontal/refresh`; // post
export const SORT_BANNER_CATALOG_HORIZONTAL_UPDATE = `${DOMAIN_API}/api/admin/sort/banner-catalog-horizontal/update`; // put
export const SORT_BANNER_CATALOG_HORIZONTAL_TO_POST = [`${DOMAIN_API}/api/admin/sort/banner-catalog-horizontal/`, '/to/']; // post
export const SORT_BANNER_CATALOG_HORIZONTAL_DELETE = [`${DOMAIN_API}/api/admin/sort/banner-catalog-horizontal/`, '']; // delete
export const SORT_BANNER_CATALOG_HORIZONTAL_PAGE_POSITION = [`${DOMAIN_API}/api/admin/sort/banner-catalog-horizontal/`, '/to/']; // delete

// sort/banner-header
export const SORT_BANNER_HEADER_GET = `${DOMAIN_API}/api/admin/sort/banner-header`; // get
export const SORT_BANNER_HEADER_REFRESH = `${DOMAIN_API}/api/admin/sort/banner-header/refresh`; // post
export const SORT_BANNER_HEADER_UPDATE = `${DOMAIN_API}/api/admin/sort/banner-header/update`; // put
export const SORT_BANNER_HEADER_TO_POST = [`${DOMAIN_API}/api/admin/sort/banner-header/`, '/to/']; // post
export const SORT_BANNER_HEADER_DELETE = [`${DOMAIN_API}/api/admin/sort/banner-header/`, '']; // delete
export const EDIT_SORT_BANNER_HEADER_POSITION = [`${DOMAIN_API}/api/admin/sort/banner-header/`, '/to/']; // post

// sort/banner-menu
export const SORT_BANNER_MENU_GET = `${DOMAIN_API}/api/admin/sort/banner-menu`; // get
export const SORT_BANNER_MENU_REFRESH = `${DOMAIN_API}/api/admin/sort/banner-menu/refresh`; // post
export const SORT_BANNER_MENU_UPDATE = `${DOMAIN_API}/api/admin/sort/banner-menu/update`; // put
export const SORT_BANNER_MENU_TO_POST = [`${DOMAIN_API}/api/admin/sort/banner-menu/`, '/to/']; // post
export const SORT_BANNER_MENU_DELETE = [`${DOMAIN_API}/api/admin/sort/banner-menu/`, '']; // delete
export const EDIT_BANNER_POSITION = [`${DOMAIN_API}/api/admin/sort/banner-menu/`, '/to/']; // post

// sort/banner-catalog-main-page
export const SORT_BANNER_MAIN_PAGE_GET = `${DOMAIN_API}/api/admin/sort/banner-main-page`; // get
export const SORT_BANNER_MAIN_PAGE_REFRESH = `${DOMAIN_API}/api/admin/sort/banner-main-page/refresh`; // post
export const SORT_BANNER_MAIN_PAGE_UPDATE = `${DOMAIN_API}/api/admin/sort/banner-main-page/update`; // put
export const SORT_BANNER_MAIN_PAGE_TO_POST = [`${DOMAIN_API}/api/admin/sort/banner-main-page/`, '/to/']; // post
export const SORT_BANNER_MAIN_PAGE_DELETE = [`${DOMAIN_API}/api/admin/sort/banner-main-page/`, '']; // delete
export const SORT_BANNER_MAIN_PAGE_POSITION = [`${DOMAIN_API}/api/admin/sort/banner-main-page/`, '/to/']; // post

// sort/special-offers
export const SORT_SPECIAL_OFFERS_GET = `${DOMAIN_API}/api/admin/sort/special-offers`; // get
export const SORT_SPECIAL_OFFERS_REFRESH = `${DOMAIN_API}/api/admin/sort/special-offers/refresh`; // post
export const SORT_SPECIAL_OFFERS_UPDATE = `${DOMAIN_API}/api/admin/sort/special-offers/update`; // put
export const SORT_SPECIAL_OFFERS_TO_POST = [`${DOMAIN_API}/api/admin/sort/special-offers/`, '/to/']; // post
export const SORT_SPECIAL_OFFERS_DELETE = [`${DOMAIN_API}/api/admin/sort/special-offers/`, '']; // delete

// sort/categories
export const SORT_CATEGORIES_GET = `${DOMAIN_API}/api/admin/sort/categories`; // get
export const SORT_CATEGORIES_REFRESH = `${DOMAIN_API}/api/admin/sort/categories/refresh`; // post
export const SORT_CATEGORIES_UPDATE = `${DOMAIN_API}/api/admin/sort/categories/update`; // put
export const SORT_CATEGORIES_TO_POST = [`${DOMAIN_API}/api/admin/sort/categories/`, '/to/']; // post
export const SORT_CATEGORIES_DELETE = [`${DOMAIN_API}/api/admin/sort/categories/`, '']; // delete
export const EDIT_SORT_PRODUCTS_POSITION = [`${DOMAIN_API}/api/admin/sort/products/`, '/to/']; // put
export const EDIT_SORT_CATEGORIES_POSITION = [`${DOMAIN_API}/api/admin/sort/categories/`, '/to/']; // post

// documents
export const DOCUMENTS_GET = `${DOMAIN_API}/api/admin/documents`; // get
export const DOCUMENT_CREATE = `${DOMAIN_API}/api/admin/documents`; // post
export const DOCUMENT_GET_EDIT = [`${DOMAIN_API}/api/admin/documents/`, '/edit']; // get
export const DOCUMENT_EDIT = [`${DOMAIN_API}/api/admin/documents/`, '']; // put
export const DOCUMENT_DELETE = [`${DOMAIN_API}/api/admin/documents/`, '']; // delete
export const DOCUMENTS_TYPES_GET = `${DOMAIN_API}/api/admin/documents/types`; // get
export const DOCUMENTS_TO_POST = (id, move) => `${DOMAIN_API}/api/admin/documents/${id}/to/${move}`; // post
export const DOCUMENTS_REFRESH_POST = `${DOMAIN_API}/api/admin/documents/refresh`; // post
export const DOCUMENTS_UPDATE_POST = `${DOMAIN_API}/api/admin/documents/update`; // put

// collections
export const SELECTIONS_GET = `${DOMAIN_API}/api/admin/selections/`; // get
export const SELECTION_CREATE = `${DOMAIN_API}/api/admin/selections`; // post
export const SELECTION_GET_EDIT = [`${DOMAIN_API}/api/admin/selections/`, '/edit']; // get
export const SELECTION_EDIT = [`${DOMAIN_API}/api/admin/selections/`, '']; // put
export const SELECTION_DELETE = [`${DOMAIN_API}/api/admin/selections/`, '']; // delete

export const SELECTION_CATEGORY_DELETE = (id, catId) => `${DOMAIN_API}/api/admin/selections/${id}/categories/${catId}`; // delete

// sections
export const SECTIONS_GET = `${DOMAIN_API}/api/admin/sections/`; // get
export const SECTIONS_CREATE = `${DOMAIN_API}/api/admin/sections`; // post
export const SECTIONS_GET_CREATE = `${DOMAIN_API}/api/admin/sections/create`; // get
export const SECTIONS_NODE_AFTER_NEIGHBOR_EDIT = (nodeId, neighbor) => `${DOMAIN_API}/api/admin/sections/${nodeId}/after/${neighbor}`; // put
export const SECTIONS_NODE_BEFORE_NEIGHBOR_EDIT = (nodeId, neighbor) => `${DOMAIN_API}/api/admin/sections/${nodeId}/before/${neighbor}`; // put
export const SECTIONS_PARENT_APPEND_NODE_EDIT = (parent, nodeId) => `${DOMAIN_API}/api/admin/sections/${parent}/append/${nodeId}`; // put
export const SECTION_SWITCH_ROLE_EDIT = (sectionId, roleId) => `${DOMAIN_API}/api/admin/sections/${sectionId}/switch-role/${roleId}`; // put
export const SECTION_GET = [`${DOMAIN_API}/api/admin/sections/`, '']; // get
export const SECTIONS_EDIT = [`${DOMAIN_API}/api/admin/sections/`, '']; // put
export const SECTIONS_DELETE = [`${DOMAIN_API}/api/admin/sections/`, '']; // delete
export const SECTIONS_GET_EDIT = [`${DOMAIN_API}/api/admin/sections/`, '/edit']; // get
export const SECTION_MENU_GET = `${DOMAIN_API}/api/admin/section-menu/`; // get

export const SERVICE_CENTERS_GET = `${DOMAIN_API}/api/admin/service-centers/registrations`; //get
export const SERVICE_CENTERS_LIST_GET = `${DOMAIN_API}/api/admin/service-centers`; //get

// binotel
export const BINOTEL_RECORD_LINK = [`${DOMAIN_API}/api/admin/binotel/`, '/record-link']
