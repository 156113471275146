import React, {useEffect, useMemo, useState} from 'react';
import cn from "classnames";
import s from "../SpecOfferEdit.module.scss";
import parseCategoriesActionNodes from "../../../lib/helpers/parseCategoriesActionNodes";
import Loader from "../../../components/ui/Loader";
import ProductBlockNew from "./block/ProductBlockNew";
import ProductBlock from "./block/ProductBlock";
import useList from "../../../lib/crud/useList";
import {getData, getFiltersData} from "../../RoleList/api";
import {useNavigate} from "react-router-dom";

const TypeThreeNew = ({
    activeTab,
    setActiveTab,
    tabList,
    domainWatch,
    data,
    formH,
    discountPercent,
    discountValue,
    priceType,
}) => {
    const [categories, setCategories] = useState([]);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();

    const categoriesList = useMemo(() => {
        return data?.categories?.filter(item => item.domain_id.toString() === domainWatch?.toString());
    }, [data, domainWatch]);

    useEffect(() => {
        setCategories(parseCategoriesActionNodes(categoriesList))
    }, [categoriesList, domainWatch]);

    return (
        <div style={{width: "100%"}}>
            <ul className={s.tab}>
                {!!tabList.length && tabList.map((el, index) => {
                    return (
                        <li className={cn(s.tab__item, {
                            ['active']: el.id === activeTab,
                            [s.tab__line]: el.id === activeTab,
                        })} key={index}>
                            <div onClick={() => {setActiveTab(el.id); const params = new URLSearchParams(); navigate(`?${params.toString()}`, { replace: true })}}>{el.title}</div>
                        </li>
                    )
                })}
            </ul>

            <ProductBlockNew
                name="special_offer_type3_products"
                sendName="special_offer_type3_product"
                title="Товар А"
                domainWatch={domainWatch}
                data={data}
                formH={formH}
                activeTab={activeTab}
                showActive={0}
            />

            {/* TAB B */}
            <ProductBlockNew
                name="type3_bonus_products"
                sendName="special_offer_type3_bonus_product"
                title="Товар Б"
                domainWatch={domainWatch}
                data={data}
                formH={formH}
                activeTab={activeTab}
                showActive={1}
                priceOrder
                showBtnCalculate
                discountPercent={discountPercent}
                discountValue={discountValue}
                priceType={priceType || 'promo'}
            />

            {load && <Loader/>}
        </div>
    );
};

export default TypeThreeNew;
