import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove,
} from './api';
import s from './AccessoryRelation.module.scss';
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import CategoryWithProduct from "./components/CategoryWithProduct/CategoryWithProduct";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const AccessoryRelationEditView = () => {
  const { id: asID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
  } = formH;

  const { load, data, submitHandler } = useEdit({
    targetID: asID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/accessory-relation/not-found',
      remove: `/app/accessory-relations${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/accessory-relations${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Аксессураная связь создана',
      edit: 'Аксессураная связь обновлена',
      remove: 'Аксессураная связь удалена',
    },
  });

  const domainIdWatch = formH.watch('domain_id');

  /* eslint-disable */
  return (
      <div className={s.inner}>
          <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Accessory edit`} />
          <Form attrs={{
            onSubmit: handleSubmit(submitHandler),
          }}
          >
            <button type="submit" className="d-hide" />

            <div className={s.top}>
              <div className={s.top__block}>
                {/* title */}
                <div>
                  <InputWrapper label="Название аксессуарной связи" id="im-title">
                    <input
                        className={cn(s_Input.input, {
                          'is-error': errors.title,
                        })}
                        type="text"
                        placeholder="Название аксессуарной связи"
                        ref={register({
                          required: VALIDATION.req.required,
                        })}
                        defaultValue={data?.fields?.data?.title}
                        maxLength={INPUT_MAX_LENGTH}
                        name="title"
                        id="im-title"
                    />
                  </InputWrapper>
                  {errors.title && <p className="form-input-hint">{errors.title.message}</p>}
                </div>

                {/* domain_id */}
                <div>
                  <div className={s_Select.select__inner}>
                    <div className={s_Select.select__wrapper}>
                      {data
                          ? (
                              <select
                                  name="domain_id"
                                  className={cn(s_Select.select, {
                                    [s_Select.select_error]: errors?.domain_id,
                                  })}
                                  id="or-domain"
                                  ref={register({
                                    required: VALIDATION.select.required,
                                  })}
                              >
                                <option value="">Выберите домен</option>
                                {data && data.domains.map((domain) => (
                                    <option
                                        key={domain.id}
                                        value={domain.id}
                                        selected={domain.id === data.fields?.data?.domain_id}
                                    >
                                      {domain.title}
                                    </option>
                                ))}
                              </select>
                          )
                          : (
                              <div className="p-relative">
                                <div className="loading" />
                              </div>
                          )}
                      <label className={s_Select.label} htmlFor="or-domain">Домен*</label>
                    </div>
                    {errors?.domain_id && <p className="form-input-hint">{errors.domain_id?.message}</p>}
                  </div>
                </div>
              </div>

              {/* is_active */}
              <div className={s.top__checked}>
                <CheckboxSwitch
                    id="ar-is-active"
                    name="is_active"
                    label="Активность"
                    formH={formH}
                    defaultChecked={data?.fields?.data?.is_active}
                />
              </div>
            </div>

            {!!data && (
                <div className={s.block}>
                  <CategoryWithProduct
                      title="Основной товар"
                      data={data}
                      formH={formH}
                      domainId={domainIdWatch}
                      nameParent="product_from_ids[]"
                      name="product_from_ids"
                      selectionName="selection_from_ids"
                  />
                  <CategoryWithProduct
                      title="Дополнительный товар"
                      data={data}
                      formH={formH}
                      domainId={domainIdWatch}
                      nameParent="product_to_ids[]"
                      name="product_to_ids"
                      selectionName="selection_to_ids"
                  />
                </div>
            )}


          </Form>

          {load && <Loader />}
      </div>
  );
};

export default AccessoryRelationEditView;
