import React from 'react';
import s from "./InputWrapper.module.scss";
import cn from "classnames";

const InputWrapper = ({ id, label, children, childrenBtnRight, customStyle = {}, errorComponent, style, size = 'lg', customLabelStyle = {} }) => {
    return (
        <div
            className={s.wrapper}
            style={style}
        >
            <div className={s.input__wrapper} style={customStyle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {children}
                    {size === 'lg' && <label htmlFor={id} className={s.label} style={customLabelStyle}>{label}</label>}
                    {childrenBtnRight && childrenBtnRight}
                </div>
            </div>
            {errorComponent}
        </div>
    );
};

export default InputWrapper;