import React, {useEffect, useMemo, useState} from "react";
import s from "./CategoryWithProduct.module.scss";
import CheckboxTreeWrap from "../../../../components/ui/CheckboxTreeWrap";
import s_Parent from "./../../AccessoryRelation.module.scss";
import icon_copy from "../../../../assets/icons/icon_copy.svg";
import { getProductSearchList } from "../../../../lib/api/products";
import getImgUrl from "../../../../lib/helpers/getImgUrl";
import { getAccessoryRelationProductFrom, getAccessoryRelationProductTo } from "../../../../lib/api/accessory_relation";
import InputRadio from "../../../../components/ui/InputRadio";
import Loader from "../../../../components/ui/Loader";
import ColFull from "../../../../components/ui/FormEditView/ColFull";
import RSelect from "../../../../components/ui/RSelect";
import Group from "../../../../components/ui/FormEditView/Group";

const CategoryWithProduct = ({ title, data, domainId, nameParent, name, formH, selectionName }) => {
    const [open, setOpen] = useState(false);
    const [productsA, setProductsA] = useState([]);
    const [includedA, setIncludedA] = useState([]);
    const [productAll, setProductAll] = useState([]);
    const [productsB, setProductsB] = useState([]);
    const [includedB, setIncludedB] = useState([]);
    const [categoryId, setCategoryId] = useState([]);
    const [openExpanded, setOpenExpanded] = useState(null);
    const [loading, setLoading] = useState(false);
    const [typeChooseA, setTypeChooseA] = useState('category');
    const [typeChooseB, setTypeChooseB] = useState('category');

    const [selectedSamplesA, setSelectedSamplesA] = useState([]);
    const [selectedSamplesB, setSelectedSamplesB] = useState([]);

    console.log(typeChooseA, 'typeChooseA')
    console.log(typeChooseB, 'typeChooseB')
    console.log(selectedSamplesB, 'setSelectedSamplesB')

    const categoryMemo = useMemo(() => {
        return data?.categories?.[0]?.children || []
    }, [data?.categories?.[0]?.children])

    const getAllCategoriesID = () => {
        const result = [...categoryMemo];
        const parseCategories = result.map(item => {
            const parseSecondLevel = item.children.map(itemSecond => {
                const parseThirdLevel = itemSecond.children.map(itemThird => {
                    const parseFourthLevel = itemThird.children.map(itemFourth => itemFourth.value.split('cat_')[1]);
                    return [itemThird.value.split('cat_')[1], ...parseFourthLevel].flat();
                });
                return [itemSecond.value.split('cat_')[1], ...parseThirdLevel].flat();
            })
            return [item.value.split('cat_')[1], ...parseSecondLevel].flat();
        }).flat();
        setCategoryId(parseCategories);
    };

    const findCat = (value, array = productAll) => array.filter(itemFind => {
        if(Number.isInteger(value)) {
            return (itemFind.cat_id || itemFind.category_id) === +value;
        }
        return (itemFind.cat_id || itemFind.category_id) === +value?.split('cat_')[1];
    });

    const parseList = (type) => {
        const funcPrev = (prev) => {
            const copyPrev = [...prev];
            const result = copyPrev.map(item=> {
                if((item.value === 58) || (item.value === 'cat_58')) {
                    const mapChildren = item.children.map(child => {
                        const mapChildSec = child.children.map(childSec => {

                            const resultSecond = [];
                            resultSecond.push(findCat(childSec.value));

                            if(!!resultSecond.length) {
                                return {...childSec, value: childSec.value, children: resultSecond.flat()}
                            }
                            return childSec;
                        });
                        return {...child, value: child.value, children: mapChildSec};
                    })
                    return {...item, value: item.value, children: mapChildren};
                } else {
                    if(!!item?.children?.length) {
                        const resultSecond = [];
                        for(let i = 0; i < item?.children?.length; i++) {
                            resultSecond.push(findCat(item.children[i].value));
                        }

                        const parseChildren = item.children.map((item, index) => {
                            return ({...item, value: item.value, children: resultSecond[index]});
                        })

                        if(!!parseChildren.length) {
                            return {...item, value: item.value, children: parseChildren}
                        }

                    } else {
                        return {...item, value: item.value, children: findCat(item.value)};
                    }
                }
            })
            return result;
        };

        switch (type) {
            case 'productsA': {
                setProductsA((prev) => {
                    return funcPrev(prev);
                });
                break;
            }
            case 'productsB': {
                setProductsB((prev) => {
                    return funcPrev(prev);
                });
                break;
            }
        }
    };

    const parseSearch = (prev, array) => {
        const copyPrev = [...prev];
        const result = copyPrev.map(item=> {
            if((item.value === 58) || (item.value === 'cat_58')) {
                const mapChildren = item.children.map(child => {
                    const mapChildSec = child.children.map(childSec => {
                        const resultSecond = [];
                        const parseProduct = findCat(childSec.value, array).map(item => ({
                            value: item.id,
                            label: item.title
                        }));
                        resultSecond.push(parseProduct);

                        if(!!resultSecond.length) {
                            return {...childSec, value: childSec.value, children: resultSecond.flat()}
                        }
                        return childSec;
                    });
                    return {...child, value: child.value, children: mapChildSec};
                })
                return {...item, value: item.value, children: mapChildren};
            } else {
                if(!!item?.children?.length) {
                    const resultSecond = [];
                    for(let i = 0; i < item?.children?.length; i++) {
                        const parseProduct = findCat(item.children[i].value, array).map(item => ({
                            value: item.id,
                            label: item.title
                        }));
                        resultSecond.push(parseProduct);
                    }

                    const parseChildren = item.children.map((item, index) => {
                        return ({...item, value: item.value, children: resultSecond[index]});
                    })

                    if(!!parseChildren.length) {
                        return {...item, value: item.value, children: parseChildren}
                    }

                } else {
                    const parseProduct = findCat(item.value, array).map(item => ({
                        value: item.id,
                        label: item.title
                    }));
                    return {...item, value: item.value, children: parseProduct};
                }
            }
        });
        return result;
    };

    const productSearchA = (value) => {
        if(value.length > 2) {
            getProductSearchList({
                cat_ids: categoryId.join(','),
                domain_id: domainId,
                query: value
            }).then(res => {
                if(!!res.data.data.length) {
                    setProductsA(parseSearch(categoryMemo, res.data.data));
                    if(value !== '') {
                        setOpenExpanded(res.data.data);
                    } else {
                        setOpenExpanded(res.data.data);
                    }
                } else {
                    setProductsA([]);
                }
            });
        }
        if(value === '') {
            getProductSearchList({
                cat_ids: categoryId.join(','),
                domain_id: domainId,
                query: value
            }).then(res => {
                setProductsA(parseSearch(categoryMemo, res.data.data));
            });
        }
    };

    const productSearchB = (value) => {
        if(value.length > 2) {
            getProductSearchList({
                cat_ids: categoryId.join(','),
                domain_id: domainId,
                query: value
            }).then(res => {
                if(!!res.data.data.length) {
                    setProductsB(parseSearch(categoryMemo, res.data.data));
                    if(value !== '') {
                        setOpenExpanded(res.data.data);
                    } else {
                        setOpenExpanded(res.data.data);
                    }
                } else {
                    setProductsB([]);
                }
            });
        }

        if(value === '') {
            getProductSearchList({
                cat_ids: categoryId.join(','),
                domain_id: domainId,
                query: value
            }).then(res => {
                setProductsB(parseSearch(categoryMemo, res.data.data));
            });
        }
    };

    useEffect(() => {
        if(categoryMemo) {
            setProductsA(categoryMemo);
            setProductsB(categoryMemo);
            getAllCategoriesID();
        }
    }, [data]);

    useEffect(async () => {
        if(typeChooseA === 'product') {
            if(!!categoryId.length) {
                const getProd = await getProductSearchList({cat_ids: categoryId.join(','), domain_id: 1,}).then((r) => {
                    return r.data.data.map(item => ({
                        cat_id: item.category_id,
                        value: item.id,
                        label: `${item.title} (${item?.jde})`,
                        jde: item?.jde
                    }));
                });

                setProductAll(getProd);
            }
        }
    }, [typeChooseA]);

    useEffect(async () => {
        if(typeChooseB === 'product') {
            if(!!categoryId.length) {
                const getProd = await getProductSearchList({cat_ids: categoryId.join(','), domain_id: 1,}).then((r) => {
                    return r.data.data.map(item => ({
                        cat_id: item.category_id,
                        value: item.id,
                        label: `${item.title} (${item?.jde})`,
                        jde: item?.jde
                    }));
                });

                setProductAll(getProd);
            }
        }
    }, [typeChooseB]);

    useEffect(() => {
        if(!!productAll.length) {
            parseList('productsA');
            parseList('productsB');
        }
    }, [productAll]);

    const getProductsFrom = async () => {
        setOpen(!open);
        setLoading(true);
        if(!open) {
            await getAccessoryRelationProductFrom(data?.fields?.data?.id).then(res => {
                setIncludedA(res.data.data);
            });
            setLoading(false);
        }
    };

    const getProductsTo = async () => {
        setOpen(!open);
        setLoading(true);
        if(!open) {
            await getAccessoryRelationProductTo(data?.fields?.data?.id).then(res => {
                setIncludedB(res.data.data);
            }).catch(err => {
                console.log(err.response.message);
                setLoading(false);
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        if(!!data?.fields?.data?.[name]?.length) {
            if (name === 'product_from_ids') {
                setTypeChooseA('product');
            }

            if (name === 'product_to_ids') {
                setTypeChooseB('product');
            }
        }

        if (selectionName === 'selection_from_ids') {
            if (!!data?.fields?.data?.[selectionName]?.length) {
                setTypeChooseA('samples');
                setSelectedSamplesA(data?.fields?.data?.[selectionName].map(item => {
                    const findSelect = data?.selections?.data?.find(selection => selection?.id?.toString() === item?.toString())
                    findSelect.title_ua = findSelect.title
                    return findSelect
                }))
            }
        }

        if (selectionName === 'selection_to_ids') {
            if (!!data?.fields?.data?.[selectionName]?.length) {
                setTypeChooseB('samples');
                setSelectedSamplesB(data?.fields?.data?.[selectionName].map(item => {
                    const findSelect = data?.selections?.data?.find(selection => selection?.id?.toString() === item?.toString())
                    findSelect.title_ua = findSelect.title
                    return findSelect
                }))
            }
        }


    }, [data?.fields]);

    const handleCopyText = (value) => {
        let textField = document.createElement('textarea');
        textField.innerText = value;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    return (
        <div className={s.wrapper}>
            <div className={s.product}>
                <div className={s.product__title}>{title}</div>
                <button className={s.product__open} onClick={() => {
                    name === 'product_from_ids' ? getProductsFrom() : getProductsTo()
                }} type="button" disabled={!data?.fields?.data?.id}>
                    {open ? 'Скрыть товары' : 'Показать товары'}
                </button>
            </div>
            {open && (
                <div className={s.product__wrapper}>
                    {name === 'product_from_ids' ? (!!includedA?.length ? includedA.map(item => {
                        return (
                            <div className={s.product__block} key={item.id}>
                                <div className={s.product__box}>
                                    <div className={s.product__img}>
                                        <img src={getImgUrl(item?.image?.link)} alt={item?.image?.alt} />
                                    </div>
                                    <div>
                                        <div className={s.product__info__title}>{item.title}</div>
                                    </div>
                                </div>
                                <div className={s.product__info}>
                                    <div>{item.jde}</div>
                                    <img src={icon_copy} alt="Icon copy" onClick={() => handleCopyText(item.jde)} />
                                </div>
                            </div>
                        )
                    }) : <div>
                        Товаров нет
                        {loading && <Loader />}
                    </div>) : (!!includedB?.length ? includedB.map(item => {
                        return (
                            <div className={s.product__block} key={item.id}>
                                <div className={s.product__box}>
                                    <div className={s.product__img}>
                                        <img src={getImgUrl(item?.image?.link)} alt={item?.image?.alt} />
                                    </div>
                                    <div>
                                        <div className={s.product__info__title}>{item.title}</div>
                                    </div>
                                </div>
                                <div className={s.product__info}>
                                    <div>{item.jde}</div>
                                    <img src={icon_copy} alt="Icon copy" onClick={() => handleCopyText(item.jde)} />
                                </div>
                            </div>
                        )
                    }) : <div>
                        Товаров нет
                        {loading && <Loader />}
                    </div>)}
                </div>
            )}
            <div className={s.categories}>
                <div className={s_Parent.title}>
                    {name === "product_from_ids" && <div className={s.wrapper_radio}>
                        <div>
                            Категория товара
                            <InputRadio
                                name="product_from_ids-switch-cat"
                                id="product_from_ids-switch-cat"
                                onChange={() => setTypeChooseA('category')}
                                value={typeChooseA === 'category'}
                            />
                        </div>
                        <div>
                            Товар
                            <InputRadio
                                name="product_from_ids-switch-cat"
                                id="product_from_ids-switch-product"
                                onChange={() => setTypeChooseA('product')}
                                value={typeChooseA === 'product'}
                            />
                        </div>
                        {selectionName === 'selection_from_ids' && (
                            <div>
                                Вибірки
                                <InputRadio
                                    name={`product_from_ids-switch-cat`}
                                    id="product_from_ids-switch-samples"
                                    onChange={() => setTypeChooseA('samples')}
                                    value={typeChooseA === 'samples'}
                                />
                            </div>
                        )}
                    </div>}
                    {name === "product_to_ids" && <div className={s.wrapper_radio}>
                        <div>
                            Категория
                            <InputRadio
                                name={`product_to_ids-switch-cat`}
                                id="product_to_ids-switch-cat"
                                defaultChecked
                                onChange={() => setTypeChooseB('category')}
                                value={typeChooseB === 'category'}
                            />
                        </div>
                        <div>
                            Продукты
                            <InputRadio
                                name="product_to_ids-switch-cat"
                                id="product_to_ids-switch-product"
                                onChange={() => setTypeChooseB('product')}
                                value={typeChooseB === 'product'}
                            />
                        </div>

                        {selectionName === 'selection_to_ids' && (
                            <div>
                                Вибірки
                                <InputRadio
                                    name={`product_to_ids-switch-cat`}
                                    id="product_to_ids-switch-samples"
                                    onChange={() => setTypeChooseB('samples')}
                                    value={typeChooseB === 'samples'}
                                />
                            </div>
                        )}
                    </div>}
                </div>

                <input
                    type="hidden"
                    name={`type_choose_${name}`}
                    value={name ==="product_from_ids" ? typeChooseA : typeChooseB}
                    ref={formH.register}
                />

                <div className={s.tree_wrap}>
                    {!productsA.length && <p style={{textAlign: 'center'}}>Ничего не найдено</p>}
                    {!productsB.length && <p style={{textAlign: 'center'}}>Ничего не найдено</p>}
                    {name ==="product_from_ids"
                        ? typeChooseA === 'category' && (
                        <CheckboxTreeWrap
                            data={categoryMemo}
                            setData={() => {}}
                            formH={formH}
                            dataAll={data}
                            arrayTrigger={name ==="product_from_ids" ? productsA : productsB}
                            nameTrigger={name}
                            nameParent={nameParent}
                            openExpanded={openExpanded}
                            marginLeft={false}
                            filtered
                            expandedBtn
                        />
                    )
                        : typeChooseB === 'category' && (
                        <CheckboxTreeWrap
                            data={categoryMemo}
                            setData={() => {}}
                            formH={formH}
                            dataAll={data}
                            arrayTrigger={name ==="product_from_ids" ? productsA : productsB}
                            nameTrigger={name}
                            nameParent={nameParent}
                            openExpanded={openExpanded}
                            marginLeft={false}
                            filtered
                            expandedBtn
                        />
                    )}

                    {name ==="product_from_ids"
                        ? typeChooseA === 'product' && (
                        <CheckboxTreeWrap
                            data={name ==="product_from_ids" ? productsA : productsB}
                            setData={() => {}}
                            formH={formH}
                            dataAll={data}
                            arrayTrigger={name ==="product_from_ids" ? productsA : productsB}
                            nameTrigger={name}
                            nameParent={nameParent}
                            openExpanded={openExpanded}
                            typeChoose={name ==="product_from_ids" ? typeChooseA : typeChooseB}
                            marginLeft={false}
                            filtered
                            expandedBtn
                        />
                    )
                        : typeChooseB === 'product' && (
                        <CheckboxTreeWrap
                            data={name ==="product_from_ids" ? productsA : productsB}
                            setData={() => {}}
                            formH={formH}
                            dataAll={data}
                            arrayTrigger={name ==="product_from_ids" ? productsA : productsB}
                            nameTrigger={name}
                            nameParent={nameParent}
                            openExpanded={openExpanded}
                            typeChoose={name ==="product_from_ids" ? typeChooseA : typeChooseB}
                            marginLeft={false}
                            filtered
                            expandedBtn
                        />
                    )}

                    {selectionName === 'selection_from_ids' ? typeChooseA === 'samples' && (
                        <div>
                            <Group attrs={{className: s.rselect__wrapper}}>
                                <ColFull>
                                    <RSelect
                                        props={{
                                            value: selectionName === 'selection_from_ids' ? selectedSamplesA : selectedSamplesB,
                                            onChange: (opt) => {
                                                selectionName === 'selection_from_ids' ? setSelectedSamplesA(opt) : setSelectedSamplesB(opt)
                                            },
                                            placeholder: 'Обрати Вибірки',
                                            options: data?.selections?.data.map((el) => {
                                                return {...el, title_ua: el.title}
                                            }),
                                            isMulti: true,
                                            getOptionLabel: (opt) => opt.title_ua,
                                            getOptionValue: (opt) => opt.id,
                                        }}
                                    />

                                    {selectionName === 'selection_from_ids'
                                        ? selectedSamplesA?.length > 0 && selectedSamplesA.map((item, index) =>
                                        <input
                                            key={`selections-${item.id}`}
                                            type="hidden"
                                            ref={formH.register}
                                            name={`${selectionName}[${item.id}]`}
                                            value={item.id}
                                        />
                                    )
                                        : selectedSamplesB?.length > 0 && selectedSamplesB.map((item, index) =>
                                        <input
                                            key={`selections-${item.id}`}
                                            type="hidden"
                                            ref={formH.register}
                                            name={`${selectionName}[${item.id}]`}
                                            value={item.id}
                                        />
                                    )
                                    }
                                </ColFull>
                            </Group>
                        </div>
                    ) : typeChooseB === 'samples' && (
                        <div>
                            <Group attrs={{className: s.rselect__wrapper}}>
                                <ColFull>
                                    <RSelect
                                        props={{
                                            value: selectionName === 'selection_from_ids' ? selectedSamplesA : selectedSamplesB,
                                            onChange: (opt) => {
                                                selectionName === 'selection_from_ids' ? setSelectedSamplesA(opt) : setSelectedSamplesB(opt)
                                            },
                                            placeholder: 'Обрати Вибірки',
                                            options: data?.selections?.data.map((el) => {
                                                return {...el, title_ua: el.title}
                                            }),
                                            isMulti: true,
                                            getOptionLabel: (opt) => opt.title_ua,
                                            getOptionValue: (opt) => opt.id,
                                        }}
                                    />

                                    {selectionName === 'selection_from_ids'
                                        ? selectedSamplesA?.length > 0 && selectedSamplesA.map((item, index) =>
                                        <input
                                            key={`selections-${item.id}`}
                                            type="hidden"
                                            ref={formH.register}
                                            name={`${selectionName}[${item.id}]`}
                                            value={item.id}
                                        />
                                    )
                                        : selectedSamplesB?.length > 0 && selectedSamplesB.map((item, index) =>
                                        <input
                                            key={`selections-${item.id}`}
                                            type="hidden"
                                            ref={formH.register}
                                            name={`${selectionName}[${item.id}]`}
                                            value={item.id}
                                        />
                                    )
                                    }
                                </ColFull>
                            </Group>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default CategoryWithProduct;