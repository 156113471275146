/* eslint-disable */

export const order_status = [
    {
        id: 0,
        value: 'STATUS_IN_CART',
        name: 'В корзине',
        title: 'В корзине'
    },
    {
        id: 1,
        value: 'STATUS_PRE_ORDER',
        name: 'Предзаказ',
        title: 'Предзаказ'
    },
    {
        id: 2,
        value: 'STATUS_PRE_ORDER_NOT_ANSWER',
        name: 'Недозвон предзаказ',
        title: 'Недозвон предзаказ'
    },
    {
        id: 3,
        value: 'STATUS_READY_FOR_REGISTRATION',
        name: 'Готов к оформлению',
        title: 'Готов к оформлению'
    },
    {
        id: 4,
        value: 'STATUS_PLACED',
        name: 'Оформлен',
        title: 'Оформлен'
    },
    {
        id: 5,
        value: 'STATUS_WAITING_PAYMENT',
        name: 'Ожидает оплаты ОЧ/МР',
        title: 'Ожидает оплаты ОЧ/МР'
    },
    {
        id: 6,
        value: 'STATUS_REPORT_AVAILABILITY',
        name: 'Сообщить о наличии',
        title: 'Сообщить о наличии'
    },
    {
        id: 7,
        value: 'STATUS_READY_TO_PURCHASE',
        name: 'Готов к закупке',
        title: 'Готов к закупке'
    },
    {
        id: 8,
        value: 'STATUS_ON_PURCHASE',
        name: 'На закупке',
        title: 'На закупке'
    },
    {
        id: 9,
        value: 'STATUS_TRANSFERRED_TO_LOGISTICS',
        name: 'Передан на логиста',
        title: 'Передан на логиста'
    },
    {
        id: 10,
        value: 'STATUS_TRANSFERRED_TO_WAREHOUSE',
        name: 'Передан на доставку',
        title: 'Передан на доставку'
    },
    {
        id: 11,
        value: 'STATUS_WAITING_FOR_PICKUP_AT_THE_STORE_BOPIS',
        name: 'Ожидает выдачи в магазине (БОПИС)',
        title: 'Ожидает выдачи в магазине (БОПИС)'
    },
    {
        id: 12,
        value: 'STATUS_PICKUP',
        name: 'ПИКАП',
        title: 'ПИКАП'
    },
    {
        id: 13,
        value: 'STATUS_WAITING_FOR_PICKUP_AT_THE_STORE',
        name: 'Ожидает выдачи в магазине (ПИКАП)',
        title: 'Ожидает выдачи в магазине (ПИКАП)'
    },
    {
        id: 14,
        value: 'STATUS_ISSUING_AN_INVOICE',
        name: 'Выставление счет-фактуры',
        title: 'Выставление счет-фактуры'
    },
    {
        id: 15,
        value: 'STATUS_SHIPPED_FROM_WAREHOUSE',
        name: 'Отгружен со склада',
        title: 'Отгружен со склада'
    },
    {
        id: 16,
        value: 'STATUS_CANCELED',
        name: 'Отменен',
        title: 'Отменен'
    },
    {
        id: 17,
        value: 'STATUS_ANNULLED',
        name: 'Аннулирован',
        title: 'Аннулирован'
    },
    {
        id: 18,
        value: 'STATUS_ISSUED_MANUALLY',
        name: 'Выдан вручную',
        title: 'Выдан вручную'
    },
    {
        id: 19,
        value: 'STATUS_ISSUED_AT_THE_STORE',
        name: 'Выдан в магазине',
        title: 'Выдан в магазине'
    },
    {
        id: 20,
        value: 'STATUS_TAKEN_IN_STORE',
        name: 'Забран в магазине',
        title: 'Забран в магазине'
    },
    {
        id: 21,
        value: 'STATUS_FULFILLED',
        name: 'Заказ выполнен',
        title: 'Заказ выполнен'
    },
    {
        id: 22,
        value: 'STATUS_BANK_REFUSAL',
        name: 'Отказ банка',
        title: 'Отказ банка'
    },
    {
        id: 23,
        value: 'STATUS_LOAN_IS_ISSUED',
        name: 'Кредит выдан',
        title: 'Кредит выдан'
    },
    {
        id: 24,
        value: 'STATUS_BANK_ERROR',
        name: 'Ошибка отправки заявки в банк',
        title: 'Ошибка отправки заявки в банк'
    },
    {
        id: 25,
        value: 'STATUS_WAITING_CREDIT_DECISION',
        name: 'Ожидает кредитного решения',
        title: 'Ожидает кредитного решения'
    },
    {
        id: 26,
        value: 'STATUS_ORDER_READY_TO_DELIVERY',
        name: 'Готов к доставке',
        title: 'Готов к доставке'
    },
    {
        id: 27,
        value: 'STATUS_ORDER_READY_TO_DELIVERY_PLUS',
        name: 'Готов к доставке+',
        title: 'Готов к доставке+'
    },
    {
        id: 28,
        value: 'STATUS_ORDER_RETURNED',
        name: 'Возврат',
        title: 'Возврат'
    },
    {
        id: 29,
        value: 'STATUS_ORDER_RETURNED_PART',
        name: 'Частичный возврат',
        title: 'Частичный возврат'
    }
]

export const in_cart_status = [
    {
        id: 0,
        value: 'STATUS_IN_CART',
        name: 'В корзине',
        title: 'В корзине'
    },
    {
        id: 117,
        value: 'STATUS_CART_ABANDON',
        name: 'Аннулирован',
        title: 'Аннулирован'
    }
]

export const order_status_payment = [
    {
        id: 1,
        value: 'STATUS_PAYMENT_NOT_PAID',
        name: 'Не оплачен',
        title: 'Не оплачен'
    },
    {
        id: 2,
        value: 'STATUS_PAYMENT_PAID',
        name: 'Оплачен',
        title: 'Оплачен'
    },
    {
        id: 3,
        value: 'STATUS_PAYMENT_RETURN',
        name: 'Возврат',
        title: 'Возврат'
    },
    {
        id: 4,
        value: 'STATUS_PAYMENT_PARTIAL_RETURN',
        name: 'Частичный возврат',
        title: 'Частичный возврат'
    }
]

export const order_type_payment = [
    {
        id: 1,
        value: 'TYPE_PAYMENT_CASH_ON_DELIVERY',
        name: 'Оплата наличными при доставке',
        title: 'Оплата наличными при доставке'
    },
    {
        id: 2,
        value: 'TYPE_PAYMENT_BANK_TRANSFER',
        name: 'Оплата по безналичному расчету (счет-фактура на предприятие)',
        title: 'Оплата по безналичному расчету (счет-фактура на предприятие)'
    },
    {
        id: 3,
        value: 'TYPE_PAYMENT_INSTALLMENT_PLAN',
        name: 'Беспроцентная рассрочка онлайн',
        title: 'Беспроцентная рассрочка онлайн'
    },
    {
        id: 4,
        value: 'TYPE_PAYMENT_ONLINE',
        name: 'Оплата картой онлайн',
        title: 'Оплата картой онлайн'
    },
    {
        id: 5,
        value: 'TYPE_PAYMENT_PARTS_PRIVATBANK',
        name: 'Оплата частями Приватбанк',
        title: 'Оплата частями Приватбанк'
    },
    {
        id: 6,
        value: 'TYPE_PAYMENT_INSTALLMENT_PRIVATBANK',
        name: 'Миттєва Розстрочка Приватбанк',
        title: 'Миттєва Розстрочка Приватбанк'
    },
    {
        id: 7,
        value: 'TYPE_PAYMENT_PARTS_MONOBANK',
        name: 'Оплата частями Монобанк',
        title: 'Оплата частями Монобанк'
    }
]

export const order_delivery_type = [
    {
        id: 1,
        value: 'DELIVERY_TYPE_NOVA_POSHTA_ADDRESS',
        name: 'Новая почта - Адресная доставка',
        title: 'Новая почта - Адресная доставка'
    },
    {
        id: 2,
        value: 'DELIVERY_TYPE_NOVA_POSHTA_WAREHOUSE',
        name: 'Новая почта - Отделение Новой почты',
        title: 'Новая почта - Отделение Новой почты'
    },
    {
        id: 3,
        value: 'DELIVERY_TYPE_DELFAST_FAST',
        name: 'Delfast - Быстрая доставка',
        title: 'Delfast - Быстрая доставка'
    },
    {
        id: 4,
        value: 'DELIVERY_TYPE_DELFAST_EXPRESS',
        name: 'Delfast - Экспресс-доставка',
        title: 'Delfast - Экспресс-доставка'
    },
    {
        id: 5,
        value: 'DELIVERY_TYPE_JUSTIN_WAREHOUSE',
        name: 'Justin - Отделение Justin',
        title: 'Justin - Отделение Justin'
    },
    {
        id: 6,
        value: 'DELIVERY_TYPE_PICKUP_RETAIL_STORE',
        name: 'Самовывоз - Розничный магазин',
        title: 'Самовывоз - Розничный магазин'
    },
    {
        id: 7,
        value: 'DELIVERY_TYPE_PICKUP_POINT',
        name: 'Самовывоз - Пункт самовывоза',
        title: 'Самовывоз - Пункт самовывоза'
    },
    {
        id: 8,
        value: 'DELIVERY_TYPE_PROTORIA',
        name: 'курьером Протория',
        title: 'курьером Протория'
    }
]

export const getOrderDeliveryTypeName = (type) => order_delivery_type.find((el) => type?.toString() === el.id?.toString())
export const getOrderTypePaymentName = (type) => order_type_payment.find((el) => type?.toString() === el.id?.toString())
export const getOrderStatusPaymentName = (type) => order_status_payment.find((el) => type?.toString() === el.id?.toString())
export const getOrderStatusName = (type) => order_status.find((el) => type?.toString() === el.id?.toString())
export const getInCartStatusName = (type) => in_cart_status.find((el) => type?.toString() === el.id?.toString())

export const order_reports_columns = [
    {
        id: 1,
        value: '1',
        title: 'Дата создания'
    },
    {
        id: 2,
        value: '2',
        title: 'Время создания'
    },
    {
        id: 3,
        value: '3',
        title: 'Месяц создания'
    },
    {
        id: 4,
        value: '4',
        title: 'Дата планируемой отгрузки'
    },
    {
        id: 5,
        value: '5',
        title: 'Номер заказа'
    },
    {
        id: 44,
        value: '44',
        title: 'Дата создания реализации'
    },
    {
        id: 46,
        value: '46',
        title: 'Дата выдачи заказа БОПИС'
    },
    {
        id: 6,
        value: '6',
        title: 'Магазин создателя - Домен или менеджером'
    },
    {
        id: 7,
        value: '7',
        title: 'Как создан'
    },
    {
        id: 8,
        value: '8',
        title: 'Менеджер-создатель - Ответственный менеджер'
    },
    {
        id: 9,
        value: '9',
        title: 'Статус заказа'
    },
    {
        id: 10,
        value: '10',
        title: 'Склад отгрузки'
    },
    {
        id: 52,
        value: '52',
        title: 'Запрос магазина'
    },
    {
        id: 11,
        value: '11',
        title: 'Товарная группа 2-го уровня (Категория товара)'
    },
    {
        id: 12,
        value: '12',
        title: 'JDE код товара'
    },
    {
        id: 13,
        value: '13',
        title: 'Артикул товара'
    },
    {
        id: 14,
        value: '14',
        title: 'Наименование товара из заказа'
    },
    {
        id: 15,
        value: '15',
        title: 'Количество товара'
    },
    {
        id: 18,
        value: '18',
        title: 'Сумма - Стоимость товара'
    },
    {
        id: 49,
        value: '49',
        title: 'Скидка Visa, абс'
    },
    {
        id: 50,
        value: '50',
        title: 'Промокод скидка'
    },
    {
        id: 51,
        value: '51',
        title: 'Студенческий скидка'
    },
    {
        id: 17,
        value: '17',
        title: 'Скидка по программе лояльности'
    },
    {
        id: 16,
        value: '16',
        title: 'Скидка'
    },
    {
        id: 47,
        value: '47',
        title: 'Общая сумма заказа'
    },
    {
        id: 19,
        value: '19',
        title: 'Без скидки - Стоимость товара без скидки'
    },
    {
        id: 43,
        value: '43',
        title: 'ПКС'
    },
    {
        id: 20,
        value: '20',
        title: 'Статус товара'
    },
    {
        id: 21,
        value: '21',
        title: 'Способ оплаты'
    },
    {
        id: 22,
        value: '22',
        title: 'Бренд - Заменяем на выбор Характеристики'
    },
    {
        id: 23,
        value: '23',
        title: 'Служба доставки'
    },
    {
        id: 24,
        value: '24',
        title: 'Регион доставки (Город доставки)'
    },
    {
        id: 25,
        value: '25',
        title: 'Кредитный продукт'
    },
    {
        id: 26,
        value: '26',
        title: 'Банк-кредит'
    },
    {
        id: 27,
        value: '27',
        title: 'Причина отказа'
    },
    {
        id: 28,
        value: '28',
        title: 'ТТН'
    },
    {
        id: 29,
        value: '29',
        title: 'Добавил товар - Ответственный менеджер товара'
    },
    {
        id: 30,
        value: '30',
        title: 'Сайт - Домены'
    },
    {
        id: 31,
        value: '31',
        title: 'Телефон покупателя'
    },
    {
        id: 32,
        value: '32',
        title: 'Имя покупателя'
    },
    {
        id: 33,
        value: '33',
        title: 'Фамилия покупателя'
    },{
        id: 45,
        value: '45',
        title: 'По-батькові'
    },
    {
        id: 34,
        value: '34',
        title: 'E-mail покупателя'
    },
    {
        id: 35,
        value: '35',
        title: 'Комментарий покупателя'
    },
    {
        id: 36,
        value: '36',
        title: 'Комментарий'
    },
    {
        id: 37,
        value: '37',
        title: 'Статус доставки ТТН'
    },
    {
        id: 38,
        value: '38',
        title: 'Промокод'
    },
    {
        id: 39,
        value: '39',
        title: 'Оператор чата'
    },
    {
        id: 40,
        value: '40',
        title: 'Предзаказ Состав заказа'
    },
    {
        id: 41,
        value: '41',
        title: 'Компания'
    },
    {
        id: 48,
        value: '48',
        title: 'Проект'
    },
    {
        id: 42,
        value: '42',
        title: 'Спец. Предложение'
    }
]
// last id 52
