import _parseApi, { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import {
  getCatTree,
} from '../../lib/api/categories';
import { getMarkersAll } from '../../lib/api/markers';
import {
  createSpecOffer,
  editSpecOffer,
  getSpecOfferEdit,
  getSpecOffersALl,
  getSpecOffersOptions,
  removeSpecOffer,
  specOfferPriceType,
} from '../../lib/api/specOffers';
import { getPayments } from "../../lib/api/payments";
import { getUnaccountedProducts } from '../../lib/api/unaccounted_products';
import { getAdditionalServicesAll } from '../../lib/api/additional_services';
import { BONUS_TYPE } from '../../lib/const/bonusType';
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (soId) => Promise.allSettled([getDomains(), getMarkersAll(), getCatTree(),
  soId && getSpecOfferEdit(soId), getAdditionalServicesAll(), getUnaccountedProducts({ per_page: 500 }),
  getPayments({per_page: 20}), getSpecOffersALl(), getSpecOffersOptions(), specOfferPriceType()])
  .then(async (res) => {
    const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

    if (findStatus401) {
      return await refreshToken(() => window.location.reload())
    }

    const parseSpecialOffers = !!res[7]?.value?.data?.length
        ? res[7]?.value?.data?.map(({ id, title_ua, title_ru, created_at, domain_id }) => ({
          id,
          title_ua,
          title_ru,
          created_at,
          domain_id
        }))?.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        : []

    return ({
      domains: res[0]?.value?.data,
      markers: res[1]?.value?.data,
      categories: res[2]?.value?.data,
      fields: soId && res[3]?.value?.data,
      additional_services: res[4]?.value?.data,
      unaccounted_products: res[5]?.value?.data?.data,
      payment_type: res[6]?.value?.data?.data,
      bonus_type: BONUS_TYPE,
      special_offers: parseSpecialOffers,
      special_offers_options: res[8]?.value?.data,
      special_offers_price_type: res[9]?.value?.data
    })
  });

export const create = createSpecOffer;
export const edit = editSpecOffer;
export const remove = removeSpecOffer;

export const parseDataSend = (data, soId) => {
  let d = { ...data };
  const isUpdate = typeof soId !== 'undefined';
  d['date_from'] = `${d.date_from} ${d.time_from}`;
  d['date_to'] = `${d.date_to} ${d.time_to}`;

  d = parseAll(d, !isUpdate, [
    'has_gift',
  ]);

  if (!d?.options?.length) {
    d.options = [];
  }

  if(d.special_offer_type5_promo_code){
    d = _parseApi.parseAllArr(d, [
      {
        name: 'special_offer_type5_promo_code',
        removeEmt: true,
      },
    ], true);
  }

  if(d.special_offer_type7_promo_code){
    d = _parseApi.parseAllArr(d, [
      {
        name: 'special_offer_type7_promo_code',
        removeEmt: true,
      },
    ], true);
  }

  // TYPE SECOND
  if(d.special_offer_type2_product_gift){
    d = _parseApi.parseArrAllNumber(d, 'special_offer_type2_product_gift');
  }

  if (d?.type?.toString() === '3') {
    if (!!d?.compatible_special_offers?.length) {
      d.compatible_special_offers = d.compatible_special_offers.map(item => +item);
    } else {
      d.compatible_special_offers = [];
    }
  }

    // TYPE THREE
    if(d.special_offer_type3_bonus_product){
        d = _parseApi.parseArrAllNumber(d, 'special_offer_type3_bonus_product');
    }

  // TYPE SIX
  if(d.special_offer_type6_bonus_product1) {
    d = _parseApi.parseArrAllNumber(d, 'special_offer_type6_bonus_product1');
  }

  if(d.special_offer_type6_bonus_product2){
    d = _parseApi.parseArrAllNumber(d, 'special_offer_type6_bonus_product2');
  }

  if(!!d?.special_offer_payments?.length) {
    d['special_offer_payments'] = d?.special_offer_payments?.filter(item => typeof item.payment_type === "string");
  }

  if(typeof d?.special_offer_type1_product === 'string') {
    d.special_offer_type1_product = []
  }

  if(!!d?.delivery_ids?.length) {
    d['delivery_ids'] = d?.delivery_ids?.filter(item => typeof item.delivery_type === "string").map(item => +item.delivery_type);
  }

  if(!!d?.special_offer_type1_product?.length) {
    d.special_offer_type1_product = d.special_offer_type1_product?.map(item => {
      if (item?.product_id) {
        const temp = {...item};
        if (temp?.discount_value?.length === 0) {
          temp.discount_value = null;
        }

        if (typeof temp?.discount_value === 'string') {
          temp.discount_value = +temp.discount_value;
        }

        if (typeof temp?.product_id === 'string') {
          temp.product_id = +temp.product_id;
        }

        return temp;
      }

      return {
        product_id: item,
        discount_value: null

      };
    })
  }
  if(typeof d?.special_offer_type1_bonus_type1 === 'string') {
    d.special_offer_type1_bonus_type1 = []
  }
  if(typeof d?.special_offer_type1_bonus_type2 === 'string') {
    d.special_offer_type1_bonus_type2 = []
  }

  if(typeof d?.special_offer_type2_product === 'string') {
    d.special_offer_type2_product = []
  }
  if(typeof d?.special_offer_type2_product_gift === 'string') {
    d.special_offer_type2_product_gift = []
  }

  if(typeof d?.special_offer_type3_product === 'string') {
    d.special_offer_type3_product = []
  } else {
      d.special_offer_type3_product = d.special_offer_type3_product?.map(e => e).map((item, index) => ({product_id: item.product_id, position: index + 1}))
  }
    console.log(d)
  if(typeof d?.special_offer_type3_bonus_product === 'string') {
    d.special_offer_type3_bonus_product = []
  } else {
      d.special_offer_type3_bonus_product = d.special_offer_type3_bonus_product?.map((e, index) => ({
          product_id: e?.product_id,
          price_order: e?.price_order || 0,
          position: index + 1
      }))
  }

  if(typeof d?.special_offer_type6_product === 'string') {
    d.special_offer_type6_product = []
  }
  if(typeof d?.special_offer_type6_bonus_product1 === 'string') {
    d.special_offer_type6_bonus_product1 = []
  }
  if(typeof d?.special_offer_type6_bonus_product2 === 'string') {
    d.special_offer_type6_bonus_product2 = []
  }

  if (!!d?.compatible_special_offers?.length) {
    d.compatible_special_offers = d.compatible_special_offers?.map(item => +item);
  } else {
    d.compatible_special_offers = [];
  }

  delete d['date_from-dop'];
  delete d['date_to-dop'];
  delete d['time_from'];
  delete d['time_to'];

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  if(d.special_offer_type6_bonus_product1){
    const special_offer_type6_bonus_products1 = d.special_offer_type6_bonus_product1;
    d.special_offer_type6_bonus_products1 = special_offer_type6_bonus_products1;
    delete d.special_offer_type6_bonus_product1;
  }

  if(d.special_offer_type6_bonus_product2){
    const special_offer_type6_bonus_products2 = d.special_offer_type6_bonus_product2;
    d.special_offer_type6_bonus_products2 = special_offer_type6_bonus_products2;
    delete d.special_offer_type6_bonus_product2;
  }

  return d;
};
