import React, { Fragment, useEffect, useState } from "react";
import Button from "../../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import s_Table from '../orderTable.module.scss';
import icon_lock from '../../../../assets/icons/icon_lock.svg';
import icon_unlock from "../../../../assets/icons/icon_unlock.svg";
import cn from "classnames";
import s from "../../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import {createOrderInvoice, getPaymentProviders} from "../../../../lib/api/order";
import { toast } from "react-toastify";
import errorMessage from "../../../../lib/errorMessage";
import s_Select from "../orderStyle.module.scss";
import s_Input from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import CopyComponent from "../../../../components/ui/CopyComponent";
import SliderMui from "../../../../components/ui/SliderMui/SliderMui";
import { scrollIntoViewHelper } from "../../../../lib/helpers/scrollIntoViewHelper";
import InputWrapper from "../../../../components/ui/InputWrapper";

const ModalInvoice = ({
                          active,
                          setActive,
                          productsData,
                          title,
                          orderStatus,
                          setRequiredFields,
                          id,
                          formProps
}) => {
    const [load, setLoad] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [chooseProducts, setChooseProducts] = useState([]);
    const [chooseServices, setChooseServices] = useState([]);
    const [chooseSpecOffers, setChooseSpecOffers] = useState([]);
    const [responseInvoice, setResponseInvoice] = useState(null);
    const [paymentProviders, setPaymentProviders] = useState([]);
    const [choosePaymentCount, setChoosePaymentCount] = useState(6);
    const [validMax, seValidMax] = useState(25);

    const [invoiceName, setInvoiceName] = useState('')
    const [invoiceType, setInvoiceType] = useState('pay_separate')

    const [panEnd, setPanEnd] = useState(null)

    useEffect(() => {
        if(active && orderStatus?.toString() === '3'){
            setRequiredFields(true)
        } else {
            setRequiredFields(false)
        }

    }, [active, orderStatus])

    const inputHandler = (e) => {
        const provider = paymentProviders?.find(provider => provider?.value === invoiceName);

        if(!provider) {
            return null; // Если провайдер не найден
        }

        const excludedPaymentCount = provider?.options?.excluded_payment_count || [];
        const maxPaymentCount = provider?.options?.max_payment_count || 25;

        const validValues = [];
        for(let i = 2; i <= maxPaymentCount; i++){
            if(!excludedPaymentCount.includes(i)) {
                validValues.push(i);
            }
        }

        const validMax = validValues.length > 0 ? Math.max(...validValues) : maxPaymentCount;
        if(validValues.includes(e?.target?.value)) {
            setChoosePaymentCount(e?.target?.value);
            seValidMax(validMax)
        }
    };

    const findFirstAvailablePaymentCount = (provider) => {
        const { max_payment_count, excluded_payment_count } = provider.options;
        for (let i = 2; i <= max_payment_count; i++) {
            if (!excluded_payment_count.includes(i)) {
                return i;
            }
        }
        return 2;
    };

    const handleChangeDisabled = () => setDisabled(!disabled);

    const handleClickInvoiceProduct = async (linkInfo = false) => {
        if(!Object?.values(formProps?.formH?.errors)?.length) {
            const parseProduct = [
                ...chooseProducts,
                ...chooseServices,
                ...chooseSpecOffers
            ].map(item => {
                delete item.send;
                return item;
            });

            setLoad(true);

            const data = {
                payment_provider: invoiceName,
                items: [...parseProduct]
            }
            if (!!paymentProviders?.length && paymentProviders.some((provider) => provider?.value === invoiceName && provider?.options?.max_payment_count)) {
                data.payment_options = {parts_count: choosePaymentCount}
            }

            if (['Liqpay'].includes(invoiceName)) {
                delete data['payment_options'];
            }

            if (invoiceName === 'sensebank_pp') {
                data.payment_options.pan_end = String(panEnd).padStart(4, '0')
            }

            const handleCreateOrderInvoice = async () => {
                await createOrderInvoice(id, data).then(async (res) => {
                    if(res.status === 200) {
                        toast('Інвойс створено', {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        if (linkInfo) {
                            setResponseInvoice(res.data);
                        } else {
                            window.location.reload()
                            setActive(false);
                        }

                    }
                })
                    .catch(async (err) => {
                        if (err?.response?.status === 401) {
                            await refreshToken(async () => {
                                await handleCreateOrderInvoice()
                            })
                        } else {
                            await errorMessage(err, '')
                        }
                    })
                    .finally(() => setLoad(false));
            }

            await handleCreateOrderInvoice()
        } else {
            scrollIntoViewHelper(formProps?.formH?.errors);
            toast.error("Заполните все поля", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setActive(false)
        }
    }

    const handleChange = (e, item, invoiceableType, keyProduct = 'quantity', name, setArray) => {
        setArray(prev => {
            if (!!prev?.length) {
                const temp = [...prev];
                const findProductIndex = temp.findIndex(itemArray => itemArray.invoiceable_id === item.id);
                if (findProductIndex !== -1) {
                    temp[findProductIndex][name] = e.target.value;
                } else {
                    temp.push({ send: true, invoiceable_id: item.id, invoiceable_type: invoiceableType, [name]: e.target.value });
                }
                return temp;
            } else {
                return [{ send: true, invoiceable_id: item.id, invoiceable_type: invoiceableType, [name]: e.target.value }];
            }
        })
    };

    useEffect(() => {
        if (invoiceType === 'pay_full') {
            if (!!productsData?.length) {
                const findProducts = [];
                const findProductsAdditionalServices = [];
                const findProductsOffers = [];

                productsData.forEach((product) => {
                    findProducts.push({
                        send: true, invoiceable_id: product.id, quantity: product.quantity, price: product.product.total, invoiceable_type: 'products'
                    })

                    if (!!product?.product?.additional_services?.length) {
                        product?.product?.additional_services?.forEach((service) => {
                            findProductsAdditionalServices.push({
                                send: true,
                                invoiceable_id: service.id,
                                quantity: service.quantity,
                                price: service.total,
                                invoiceable_type: 'product_additional_services'
                            })
                        })
                    }

                    if (!!product?.product?.special_offers?.length) {
                        product?.product?.special_offers?.forEach((offer) => {
                            findProductsOffers.push({
                                send: true,
                                invoiceable_id: offer.id,
                                quantity: offer.quantity,
                                price: offer.total,
                                invoiceable_type: 'product_special_offers'
                            })
                        })
                    }
                })

                setChooseProducts(findProducts);
                setChooseServices(findProductsAdditionalServices);
                setChooseSpecOffers(findProductsOffers);
            }
        } else {
            setChooseProducts([]);
            setChooseServices([]);
            setChooseSpecOffers([]);
        }
    }, [invoiceType]);

    useEffect(() => {
        const handleGetProviders = async () => {
            await getPaymentProviders(id).then(res => {
                setPaymentProviders(res.data)
                setInvoiceName(res.data?.[0]?.value)
            }).catch(async (err) => {
                if (err?.response?.status === 401) {
                    await refreshToken(async () => handleGetProviders())
                } else {
                    await errorMessage(err, '')
                }
            })
        }

        handleGetProviders()
    }, []);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => {
                if (responseInvoice) {
                    window.location.reload()
                }
            }}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                {responseInvoice ? (
                    ['monobank', 'globus_pp', 'otp_pp', 'pumb_pp', 'abank_pp'].includes(invoiceName) ? (
                        <div className={s_Modal.modal__header} style={{ justifyContent: 'center', marginBottom: 0 }}>
                            <div className={s_Modal.modal__title} style={{marginRight: 0}}>
                                Пуш-повідомлення успішно надіслано клієнту
                            </div>
                        </div>
                    ) : (
                        <div className={s_Modal.modal__header} style={{ justifyContent: 'center' }}>
                            <div className={s_Modal.modal__title} style={{ marginRight: 0 }}>
                                Посилання на оплату <CopyComponent copyText={responseInvoice?.payment_url}/>
                            </div>
                        </div>
                    )
                ) : (
                    <div className={s_Modal.modal__header}>
                        <div>
                            <div className={s_Modal.modal__title}>{title}</div>
                        </div>
                        <div className={cn(s_Modal.modal__unlock, {
                            [s_Modal.modal__lock]: disabled,
                        })} onClick={handleChangeDisabled}>
                            {disabled ? (
                                <>
                                    <img src={icon_lock} alt="Icon lock" />
                                    Разблокировать
                                </>
                            ) : (
                                <>
                                    <img src={icon_unlock} alt="Icon lock" />
                                    Заблокировать
                                </>
                            )}
                        </div>
                    </div>
                )}
                {!load ? (
                        <div className={s_Modal.modal__box}>
                            {responseInvoice ? (
                                <>
                                    {['liqpay', 'monobank_acquiring', 'privatbank_pp', 'privatbank_ii', 'globus_pp', 'otp_pp', 'pumb_pp'].includes(invoiceName) && (
                                        <div style={{fontsize: '14px', lineHeight: '19px'}}>{responseInvoice?.payment_url}</div>)}
                                </>
                            ) : (
                                <>
                                    <div style={{ display: 'flex', gap: 16, marginBottom: 16 }}>
                                        <div className={s_Select.select__inner} style={{ marginBottom: 0, width: '100%' }}>
                                            <div className={s_Select.select__wrapper}>
                                                <select
                                                    name="invoice_type"
                                                    className={s_Select.select}
                                                    id="m-invoice-type"
                                                    onChange={(e) => setInvoiceType(e.target.value)}
                                                    value={invoiceType}
                                                >
                                                    <option value="pay_separate">Доплата за окремий товар у сплаченому замовленні</option>
                                                    <option value="pay_parts">Доплата частини суми, що бракує</option>
                                                    <option value="pay_full">Повна оплата замовлення</option>
                                                </select>
                                                <label className={s_Select.label} htmlFor="m-invoice-type">Типи інвойсів</label>
                                            </div>
                                        </div>

                                        <div className={s_Select.select__inner} style={{ marginBottom: 0, width: '100%' }}>
                                            <div className={s_Select.select__wrapper}>
                                                <select
                                                    name="invoice_name"
                                                    className={s_Select.select}
                                                    id="m-invoice-type"
                                                    onChange={(e) => {
                                                        !!paymentProviders?.length && paymentProviders.map((provider, providIndex) =>{
                                                            if(provider?.value === e.target.value) {
                                                                const firstAvailablePayment = findFirstAvailablePaymentCount(provider);
                                                                setChoosePaymentCount(firstAvailablePayment)
                                                            }
                                                        })
                                                        setInvoiceName(e.target.value)
                                                        const provider = paymentProviders?.find(provider => provider?.value === e.target.value);
                                                        if(provider?.options?.max_payment_count){
                                                            seValidMax(provider?.options?.max_payment_count)
                                                        }
                                                    }}
                                                    value={invoiceName}
                                                >
                                                    {!!paymentProviders?.length && paymentProviders.map((provider, providIndex) => {
                                                        if(invoiceType === 'pay_full') {
                                                            return (
                                                                <option style={{paddingTop: "5px", lineHeight: '2'}}
                                                                        key={`provider-item-${providIndex}-${provider.value}`} value={provider.value}>
                                                                    {provider.name}
                                                                </option>
                                                            )
                                                        } else {
                                                            if (['monobank_acquiring', 'liqpay'].includes(provider?.value)) {
                                                                return (
                                                                    <option style={{paddingTop: "5px", lineHeight: '2'}}
                                                                            key={`provider-item-${providIndex}-${provider.value}`} value={provider.value}>
                                                                        {provider.name}
                                                                    </option>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                </select>
                                                <label className={s_Select.label} htmlFor="m-invoice-type">Назва інвойсу</label>
                                            </div>
                                        </div>
                                    </div>
                                    {!!paymentProviders?.length &&
                                    paymentProviders.some(
                                        (provider) => provider?.value === invoiceName && provider?.options?.max_payment_count
                                    ) ? (
                                        (() => {
                                            const marks = Array.from({ length: validMax - 2 + 1 }, (_, i) => ({
                                                value: i + 2,
                                                label: (i + 2).toString(),
                                            }));

                                            return (
                                                <>
                                                    <div style={{ margin: '0 16px 16px 16px' }}>
                                                        <span style={{ fontSize: '16px' }}>Кількість платежів</span>

                                                        <SliderMui
                                                            defaultValue={6}
                                                            min={2}
                                                            max={validMax}
                                                            marks={marks}
                                                            onChange={inputHandler}
                                                            value={choosePaymentCount?.toString()}
                                                            valueLabelDisplay={'off'}
                                                            customStyleForMarks={true}
                                                        />
                                                    </div>

                                                    <InputWrapper
                                                        label="4 останні цифри картки"
                                                        id="order-invoice-modal-pan-end"
                                                        style={{
                                                            maxWidth: '200px'
                                                        }}
                                                    >
                                                        <input
                                                            className={s_Input.input}
                                                            type="number"
                                                            placeholder="4 останні цифри картки"
                                                            onChange={(e) => {
                                                                if (e.target.value?.length <= 4) {
                                                                    setPanEnd(e.target.value)
                                                                }
                                                            }}
                                                            value={panEnd}
                                                            name="pan_end"
                                                            id="order-invoice-modal-pan-end"
                                                        />
                                                    </InputWrapper>
                                                </>
                                            );
                                        })()
                                    ) : null}

                                    <div className={s_Table.table__inner}>
                                        <div className={s_Table.table__wrapper}>
                                            <table className={s_Table.table}>
                                                <thead className={s_Table.thead}>
                                                <tr>
                                                    <th/>
                                                    <th>
                                                        <div>JDE</div>
                                                    </th>
                                                    <th>
                                                        <div>Назва товару</div>
                                                    </th>
                                                    <th>
                                                        <div>К-ть</div>
                                                    </th>
                                                    <th>
                                                        <div>Ціна</div>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody className={s_Table.tbody}>
                                                {!!productsData.length ? productsData.map((product, indexProduct) => {

                                                    return (
                                                        <Fragment key={`modal-table-tr-product-${product?.product?.jde || indexProduct}`}>
                                                            <tr>
                                                                <td>
                                                                    <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                                        <input
                                                                            className={s.checkbox}
                                                                            type="checkbox"
                                                                            id={`modal-table-product-${indexProduct}`}
                                                                            name={`products[${indexProduct}].selected`}
                                                                            disabled={disabled || invoiceType === 'pay_full'}
                                                                            checked={!!chooseProducts?.length && chooseProducts?.find(item => item.invoiceable_id === product.id)?.send}
                                                                            onChange={(e) => {
                                                                                setChooseProducts(prev => {
                                                                                    if (!!prev?.length) {
                                                                                        const temp = [...prev];
                                                                                        const findProductIndex = temp.findIndex(item => item.invoiceable_id === product.id);

                                                                                        if (findProductIndex !== -1) {
                                                                                            temp.splice(findProductIndex, 1);
                                                                                        } else {
                                                                                            temp.push({
                                                                                                send: true,
                                                                                                invoiceable_id: product.id,
                                                                                                quantity: product.quantity,
                                                                                                price: product.product.total,
                                                                                                invoiceable_type: 'products'
                                                                                            });
                                                                                        }
                                                                                        return temp;
                                                                                    } else {
                                                                                        return [{
                                                                                            send: true,
                                                                                            invoiceable_id: product.id,
                                                                                            quantity: product.quantity,
                                                                                            price: product.product.total,
                                                                                            invoiceable_type: 'products'
                                                                                        }];
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />
                                                                        <label htmlFor={`modal-table-product-${indexProduct}`}
                                                                               className={s.label}/>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>{product?.product?.jde}</div>
                                                                </td>
                                                                <td>
                                                                    <div>{product?.product?.title_ua || product?.product?.title_ru}</div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <input
                                                                            type="number"
                                                                            placeholder={product.quantity || '0'}
                                                                            name={`products[${indexProduct}].quantity`}
                                                                            disabled={disabled || invoiceType !== 'pay_parts'}
                                                                            value={!!chooseProducts?.length
                                                                                ? chooseProducts?.find(item => item.invoiceable_id === product.id)?.quantity
                                                                                : product.quantity}
                                                                            max={product.quantity}
                                                                            readOnly={!chooseProducts?.find(item => item.invoiceable_id === product.id)?.send}
                                                                            onChange={(e) => {
                                                                                handleChange(e, product, 'products', 'quantity', 'quantity', setChooseProducts);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div>
                                                                        <input
                                                                            type="number"
                                                                            placeholder={product.product.total || '0'}
                                                                            name={`products[${indexProduct}].price`}
                                                                            disabled={disabled || invoiceType !== 'pay_parts'}
                                                                            value={!!chooseProducts?.length
                                                                                ? chooseProducts?.find(item => item.invoiceable_id === product.id)?.price
                                                                                : product.product.total}
                                                                            readOnly={!chooseProducts?.find(item => item.invoiceable_id === product.id)?.send}
                                                                            onChange={(e) => {
                                                                                handleChange(e, product, 'products', 'product.total', 'price', setChooseProducts);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {!!product?.product?.additional_services?.length && product?.product?.additional_services?.map((service, indexService) => (
                                                                <tr key={`modal-table-tr-additional-services-${service?.id || indexService}`}>
                                                                    <td>
                                                                        <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                                            <input
                                                                                className={s.checkbox}
                                                                                type="checkbox"
                                                                                disabled={disabled || invoiceType === 'pay_full'}
                                                                                id={`modal-table-product-${indexProduct}-additional-service-${indexService}`}
                                                                                name={`products[${indexProduct}].additional_service[${indexService}].selected`}
                                                                                checked={!!chooseServices?.length && chooseServices?.find(item => item.invoiceable_id === service.id)?.send}
                                                                                onChange={(e) => {
                                                                                    setChooseServices(prev => {
                                                                                        if (!!prev?.length) {
                                                                                            const temp = [...prev];
                                                                                            const findServiceIndex = temp.findIndex(item => item.invoiceable_id === service.id);

                                                                                            if (findServiceIndex !== -1) {
                                                                                                temp.splice(findServiceIndex, 1);
                                                                                            } else {
                                                                                                temp.push({
                                                                                                    send: true,
                                                                                                    invoiceable_id: service.id,
                                                                                                    quantity: service.quantity,
                                                                                                    price: service.total,
                                                                                                    invoiceable_type: 'product_additional_services'
                                                                                                });
                                                                                            }
                                                                                            return temp;
                                                                                        } else {
                                                                                            return [{
                                                                                                send: true,
                                                                                                invoiceable_id: service.id,
                                                                                                quantity: service.quantity,
                                                                                                price: service.total,
                                                                                                invoiceable_type: 'product_additional_services'
                                                                                            }];
                                                                                        }
                                                                                    })
                                                                                }}
                                                                            />
                                                                            <label htmlFor={`modal-table-product-${indexProduct}-additional-service-${indexService}`}
                                                                                   className={`${s.label} ${s_Table.label}`}/>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>{service?.additional_service?.jde}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div>{service?.additional_service?.title_ua || service?.title}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <input
                                                                                type="number"
                                                                                defaultValue={service?.quantity}
                                                                                name={`products[${indexProduct}].additional_services[${indexService}].quantity`}
                                                                                disabled={disabled || invoiceType !== 'pay_parts'}
                                                                                placeholder={service.quantity || '0'}
                                                                                value={!!chooseServices?.length
                                                                                    ? chooseServices?.find(item => item.invoiceable_id === service.id)?.quantity
                                                                                    : service?.quantity}
                                                                                max={service.quantity}
                                                                                readOnly={!chooseServices?.find(item => item.invoiceable_id === service.id)?.send}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, service, 'product_additional_services', 'quantity', 'quantity', setChooseServices);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <input
                                                                                type="number"
                                                                                defaultValue={service?.total}
                                                                                name={`products[${indexProduct}].additional_services[${indexService}].total`}
                                                                                disabled={disabled || invoiceType !== 'pay_parts'}
                                                                                placeholder={service.total || '0'}
                                                                                value={!!chooseServices?.length
                                                                                    ? chooseServices?.find(item => item.invoiceable_id === service.id)?.price
                                                                                    : service?.total}
                                                                                readOnly={!chooseServices?.find(item => item.invoiceable_id === service.id)?.send}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, service, 'product_additional_services', 'total', 'price', setChooseServices);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                            {!!product?.product?.special_offers?.length && product.product.special_offers?.map((offer, indexOffer) => {
                                                                return (
                                                                    <tr key={`modal-table-tr-special-offers-${offer?.id || indexOffer}`}>
                                                                        <td>
                                                                            <div className={`${s.checkbox__wrapper} ${s_Table.checkbox__wrapper}`}>
                                                                                <input
                                                                                    className={s.checkbox}
                                                                                    type="checkbox"
                                                                                    disabled={disabled || invoiceType === 'pay_full'}
                                                                                    id={`modal-table-product-${indexProduct}-special-offers-${indexOffer}`}
                                                                                    name={`products[${indexProduct}].special_offers[${indexOffer}].selected`}
                                                                                    checked={!!chooseSpecOffers?.length && chooseSpecOffers?.find(item => item.invoiceable_id === offer.id)?.send}
                                                                                    onChange={(e) => {
                                                                                        setChooseSpecOffers(prev => {
                                                                                            if (!!prev?.length) {
                                                                                                const temp = [...prev];
                                                                                                const findOfferIndex = temp.findIndex(item => item.invoiceable_id === offer.id);

                                                                                                if (findOfferIndex !== -1) {
                                                                                                    temp.splice(findOfferIndex, 1);
                                                                                                } else {
                                                                                                    temp.push({
                                                                                                        send: true,
                                                                                                        invoiceable_id: offer.id,
                                                                                                        quantity: offer.quantity,
                                                                                                        price: offer.total,
                                                                                                        invoiceable_type: 'product_special_offers'
                                                                                                    });
                                                                                                }
                                                                                                return temp;
                                                                                            } else {
                                                                                                return [{
                                                                                                    send: true,
                                                                                                    invoiceable_id: offer.id,
                                                                                                    quantity: offer.quantity,
                                                                                                    price: offer.total,
                                                                                                    invoiceable_type: 'product_special_offers'
                                                                                                }];
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <label htmlFor={`modal-table-product-${indexProduct}-special-offers-${indexOffer}`}
                                                                                       className={`${s.label} ${s_Table.label}`}/>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>{offer?.promo_product?.jde}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div>{offer?.promo_product?.title}</div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <input
                                                                                    type="number"
                                                                                    name={`products[${indexProduct}].special_offers[${indexOffer}].quantity`}
                                                                                    placeholder={offer.quantity || '0'}
                                                                                    defaultValue={offer?.quantity}
                                                                                    value={!!chooseSpecOffers?.length
                                                                                        ? chooseSpecOffers?.find(item => item.invoiceable_id === offer.id)?.quantity
                                                                                        : offer?.quantity}
                                                                                    max={offer.quantity}
                                                                                    disabled={disabled || invoiceType !== 'pay_parts'}
                                                                                    readOnly={!chooseSpecOffers?.find(item => item.invoiceable_id === offer.id)?.send}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e, offer, 'product_special_offers', 'quantity', 'quantity', setChooseSpecOffers);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <input
                                                                                    type="number"
                                                                                    name={`products[${indexProduct}].special_offers[${indexOffer}].price`}
                                                                                    placeholder={offer.total || '0'}
                                                                                    defaultValue={offer?.total}
                                                                                    value={!!chooseSpecOffers?.length
                                                                                        ? chooseSpecOffers?.find(item => item.invoiceable_id === offer.id)?.price
                                                                                        : offer?.total}
                                                                                    disabled={disabled || invoiceType !== 'pay_parts'}
                                                                                    readOnly={!chooseSpecOffers?.find(item => item.invoiceable_id === offer.id)?.send}
                                                                                    onChange={(e) => {
                                                                                        handleChange(e, offer, 'product_special_offers', 'total', 'price', setChooseSpecOffers);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </Fragment>
                                                    )
                                                }) : <div style={{ padding: 10 }}>Немає товарів</div>}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                    )
                    : (
                        <div className="p-relative">
                            <div className="loading" />
                        </div>
                    )}
                <div className={s_Modal.modal__footer}>
                    {responseInvoice ? (
                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button border full onClick={() => window.location.reload()} style={{ maxWidth: '150px' }}>Закрити</Button>
                        </div>
                    ) : (
                        <div className={s_Modal.modal__group} style={{justifyContent: 'flex-end'}}>
                            <div className={s_Modal.modal__buttons} style={{gap: 8}}>
                                {!!paymentProviders?.length && paymentProviders.map((provider) => {
                                    if(provider?.value === invoiceName && provider?.options?.is_link_available) {
                                        return (
                                            <>
                                                <Button
                                                    disabled={disabled || ![...chooseProducts, ...chooseServices, ...chooseSpecOffers].length || (invoiceName === 'sensebank_pp' && panEnd?.length !== 4)}
                                                    onClick={() => handleClickInvoiceProduct()}
                                                    purple
                                                >
                                                    Надіслати
                                                </Button>
                                                <Button
                                                    disabled={disabled || ![...chooseProducts, ...chooseServices, ...chooseSpecOffers].length || (invoiceName === 'sensebank_pp' && panEnd?.length !== 4)}
                                                    onClick={() => handleClickInvoiceProduct(true)}
                                                    purple
                                                >
                                                    Сформувати посилання
                                                </Button>
                                            </>
                                        )
                                    } else if(provider?.value === invoiceName && provider?.options?.is_push_available) {
                                        return (
                                            <Button
                                                disabled={disabled || ![...chooseProducts, ...chooseServices, ...chooseSpecOffers].length || (invoiceName === 'sensebank_pp' && panEnd?.length !== 4)}
                                                onClick={() => handleClickInvoiceProduct()}
                                                purple
                                            >
                                                Надіслати
                                            </Button>
                                        )
                                    }
                                })}
                            </div>
                            <div>
                                <Button border onClick={() => setActive(false)}>Закрити</Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ModalInvoice;
